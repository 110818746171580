import type { CryptId } from '@cryptid-module'
import { sortBy, uniq } from 'underscore'
import type { z } from 'zod'
import type { DocTypeState } from '~/common/doc'
import type { WithIndexNumber, ZAugmentedDoc, ZMissingDoc } from '~/common/schema'
import { AugmentedMetadataDate, type ZAugmentedCorp, docTypeStr } from '~/common/schema'
import type { ZAllAugmentedRecordsRedFlag } from '~/common/schema/red-flag'
import type { ZAugmentedRelation, ZRelationTypeValues } from '~/common/schema/relation'
import { getMetadataKeys, relationTypePrimaryDocMap } from '~/common/schema/relation/util'
import type { EnhancedDoc } from './doc'

export interface RedFlagInfo {
  isComplete: boolean
  missingDocs: ZMissingDoc[]
  redFlags: ZAllAugmentedRecordsRedFlag[]
  typesWithState: [ZAugmentedDoc['type'], DocTypeState][]
  missingWithDocs: MissingOrDoc[]
}

export const getSourceCryptIds = (
  data: ZAugmentedRelation | ZAugmentedCorp,
  shape: z.ZodRawShape
): CryptId[] => {
  const metadataKeys = getMetadataKeys(shape)
  return metadataKeys
    .map((key) => {
      const prop = data[key as keyof typeof data]
      // Check just to be sure and to keep typesafety
      return prop && typeof prop === 'object' && 'sourceCryptId' in prop
        ? prop.sourceCryptId
        : undefined
    })
    .filter(Boolean) // Remove undefined values if something wrong happened
}

type WithKey<T> = T & { key: string }

export type MissingOrDoc =
  | WithKey<ZMissingDoc>
  | EnhancedDoc
  | WithKey<WithIndexNumber<ZAugmentedDoc>>

export const getActiveStatus = (
  data: { startDate?: AugmentedMetadataDate; endDate?: AugmentedMetadataDate },
  now: Date = new Date()
): boolean =>
  (!data.startDate?.value || data.startDate.value <= now) &&
  (!data.endDate?.value || data.endDate.value > now)

export type AutofillMetadataDate = AugmentedMetadataDate & { sourceDisplay: string }

export const getStartDateAutofill = (docOpts: ZAugmentedDoc[]): AutofillMetadataDate[] => {
  const dates = docOpts.map((doc) =>
    doc.startDate
      ? {
          sourceCryptId: doc.cryptId,
          type: 'document' as const,
          value: doc.startDate,
          sourceDisplay: `${AugmentedMetadataDate.display(doc.startDate)} from ${
            doc.title ?? docTypeStr(doc.type)
          }`,
        }
      : undefined
  )
  return uniq(dates.filter(Boolean), (date) => date.value.toISOString())
}

export const orderRelationAutofillDocs = (
  type: ZRelationTypeValues | 'CORP',
  docOpts: (ZAugmentedDoc | undefined)[],
  currentDocCryptId?: CryptId
): ZAugmentedDoc[] => {
  const primaryDoc = relationTypePrimaryDocMap[type]

  return sortBy(docOpts.filter(Boolean), (item) => {
    // First, we display the current opened doc (if any)
    if (currentDocCryptId?.equals(item.cryptId)) return 0
    // Then, we show the primary doc as it's likely going to be the best suggestion
    if (item.type === primaryDoc) return 1
    // Then, all the other suggestions
    return 2
  })
}
