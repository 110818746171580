import { Table } from '@mantine/core'
import type { z } from 'zod'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { EnhancedRelation } from '~/common/enhance'
import type { ZodPersonnel, ZodUpdatePersonnel } from '~/common/schema/relation'

interface PersonnelProps<Zod extends ZodPersonnel>
  extends DetailTableProps<ZodUpdatePersonnel, EnhancedRelation<z.infer<Zod>>> {
  schema: Zod
}

export const PersonnelMetadataTable = <Zod extends ZodPersonnel>({
  schema,
  data,
  update,
  size,
}: PersonnelProps<Zod>): JSX.Element => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={schema.shape.party.mkDisplayProps(data, update, 'party')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={schema.shape.startDate.mkDisplayProps(data, update, 'startDate')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={schema.shape.endDate.mkDisplayProps(data, update, 'endDate')}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
