import Router from 'next/router'
import React from 'react'
import { create } from 'zustand'
import { useCorpCryptId } from '~/client/lib/hooks'
import type { ZAugmentedIntegration } from '~/common/schema/integration'
import { ZIntegrationType } from '~/common/schema/integration'

export const integrationModalQueryParameter: string = 'integrationModal'

interface IntegrationModalStore {
  openedIntegration: ZAugmentedIntegration['type'] | undefined
  setOpenedIntegration: (integration: ZAugmentedIntegration['type'] | undefined) => void
}

const useIntegrationModalStore = create<IntegrationModalStore>()((set) => ({
  openedIntegration: undefined,
  setOpenedIntegration: (openedIntegration) => set({ openedIntegration }),
}))

interface UseIntegrationModals {
  openedIntegration: ZAugmentedIntegration['type'] | undefined
  closeIntegrationModal: () => void
}

export const useIntegrationModals = (): UseIntegrationModals => {
  // We manage the state with the query parameters, since we get this data from
  // the callback redirect.
  const {
    [integrationModalQueryParameter]: integrationModal,
    isOnboardingIntegrations,
    ...otherQueryParameters
  } = Router.query
  // we use zustand to get typesafety over the state
  const { openedIntegration, setOpenedIntegration } = useIntegrationModalStore()
  const { mkCurrentCorpRoute } = useCorpCryptId()

  const closeIntegrationModal = React.useCallback(async () => {
    if (isOnboardingIntegrations === 'true') {
      // go to the next step (uploading documents)
      await Router.push(mkCurrentCorpRoute('dashboard', `?multidoc=onboarding-documents`))
    } else {
      await Router.replace({ query: { ...otherQueryParameters } }, undefined, {
        shallow: true,
      })
    }
    setOpenedIntegration(undefined)
  }, [isOnboardingIntegrations, mkCurrentCorpRoute, otherQueryParameters, setOpenedIntegration])

  // We have to check the query parameters for typesafety
  React.useEffect(() => {
    ;(async () => {
      if (integrationModal !== openedIntegration) {
        const safeParseResult = ZIntegrationType.safeParse(integrationModal)
        if (safeParseResult.success) {
          setOpenedIntegration(safeParseResult.data)
          return
        }
        await closeIntegrationModal()
      }
    })()
  }, [
    closeIntegrationModal,
    integrationModal,
    openedIntegration,
    otherQueryParameters,
    setOpenedIntegration,
  ])

  return { openedIntegration, closeIntegrationModal }
}
