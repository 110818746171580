import type { CryptId } from '@cryptid-module'
import { type UseFormReturnType, useForm, zodResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import React from 'react'
import { z } from 'zod'
import { useDocDetailState, useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { useCorpCryptId, useCreateDocAndUploadSingle } from '~/client/lib/hooks'
import type { ZDocType } from '~/common/schema'
import { ZAugmentedDoc, ZCreateDoc, ZUpdateDoc } from '~/common/schema'

const emptyToUndefined = (v: unknown) => (v === '' ? undefined : v)

// ZAugmentedDoc with correct date types
// We preprocess empty strings to undefined since we're using controlled components.
// We use .nullish().transform() as sometimes Mantine has null as input's value.
// .optional() would only accept `undefined`. This converts null to undefined.
export const ZDocForm = z.object({
  title: z.preprocess(emptyToUndefined, z.string().optional()),
  party: z
    .object({
      name: z.preprocess(emptyToUndefined, z.string().optional()),
      email: z.preprocess(emptyToUndefined, z.string().email().optional()),
    })
    .refine((v) => !v.email || !!v.name, {
      message: 'Please provide a name for the counterparty if defining their email',
      path: ['name'],
    }),
  startDate: z
    .date()
    .nullish()
    .transform((v) => v ?? undefined),
  // Local uploaded file, doesn't consider the remote one.
  // z.custom to have File type; z.instanceof(File) can't be used as browser doesn't know File.
  file: z.custom<File>().optional(),
  type: z.enum(ZAugmentedDoc.types, { invalid_type_error: 'Unexpected Document Type' }),
})
export interface ZDocForm extends z.infer<typeof ZDocForm> {}

const generateFormValues = (doc: ZAugmentedDoc | undefined, fallbackType: ZDocType) => {
  return {
    title: doc?.title ?? '',
    party: {
      name: doc?.party?.name ?? '',
      email: doc?.party?.email ?? '',
    },
    startDate: doc?.startDate,
    type: doc?.type ?? fallbackType,
  }
}

export const useDocForm = (): UseFormReturnType<ZDocForm> => {
  const docDetailState = useDocDetailViewStore((state) => state.docDetailViewState.prop)
  const { doc, allowedTypes = ZAugmentedDoc.types } = docDetailState ?? {}
  const miscellaneousIfAllowed = allowedTypes.includes('MISCELLANEOUS')
    ? 'MISCELLANEOUS'
    : undefined
  // Preselect the type if it was passed as a prop, otherwise try to use
  // MISCELLANEOUS if allowed to prevent filling the form with an invalid type.
  const initialType: ZDocType =
    docDetailState?.preselectedType ?? miscellaneousIfAllowed ?? allowedTypes[0] ?? 'PROCESSING'

  const form = useForm<ZDocForm>({
    initialValues: generateFormValues(doc, initialType),
    validate: zodResolver(ZDocForm),
  })

  React.useEffect(() => {
    // Update form with new values if the doc was still loading when we called
    // this hook.
    const newFormValues = generateFormValues(doc, initialType)
    form.setValues(newFormValues)
    // Reset dirty to avoid prompt to save changes when user did not interact.
    // We have to explicitly pass the initial state since the current form values do not update until next rerender
    form.resetDirty(newFormValues)

    // We do not reset the form's touched state since we don't want to
    // autocomplete with autofill if the user has already cleared it before.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, initialType])

  return form
}

export interface UseFormSaveMethods {
  /**
   * Saves the form values to the server. Creates the doc and uploads files if necessary.
   * Takes care of updating isSaving state
   * @param values
   */
  save: (values: ZDocForm) => Promise<CryptId>
  /**
   * Wrapper around save that performs additional logic like closing the modal and showing a
   * success notification. Bound to the form onSubmit event
   */
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void
}

export const useFormSaveMethods = (): UseFormSaveMethods => {
  const form = useDocDetailState((state) => state.form)
  const docCryptId = useDocDetailState((state) => state.docCryptId)
  const linkOptions = useDocDetailState((state) => state.linkOptions)
  const setIsSaving = useDocDetailState((state) => state.setIsSaving)
  const setDropError = useDocDetailState((state) => state.setDropError)
  const setFallbackDocCryptId = useDocDetailState((state) => state.setFallbackDocCryptId)

  const docDetailState = useDocDetailViewStore((state) => state.docDetailViewState.state)
  const closeDocDetail = useDocDetailViewStore((state) => state.closeModal)

  const { corpCryptId } = useCorpCryptId()
  const { createDocAndUpload } = useCreateDocAndUploadSingle()

  const parseAndUpload = async (values: ZDocForm) => {
    const { party, type, file, ...formValues } = values
    const doc = {
      ...formValues,
      party: party.name ? party : undefined,
      corpCryptId,
      type,
    }
    if (docCryptId) {
      const parsedDoc = ZUpdateDoc.parse(doc)
      const result = await createDocAndUpload({
        docCryptId,
        docInfo: parsedDoc,
      })
      return result.cryptId
    }
    if (!file) throw new Error('Cannot create a new doc without a file')
    const parsedDoc = ZCreateDoc.omit({ sha256: true }).parse(doc)
    const result = await createDocAndUpload({
      docInfo: parsedDoc,
      file,
      path: file.name,
      linkOptions,
    })
    return result.cryptId
  }

  const save = async (values: ZDocForm): Promise<CryptId> => {
    try {
      setIsSaving(true)
      // Convert `null` props to `undefined`.
      // Mantine only calls `.validate()` not `.parse()` so we call it.
      // See https://github.com/mantinedev/mantine/blob/master/src/mantine-form/src/use-form.ts
      const cryptId = await parseAndUpload(ZDocForm.parse(values))
      setFallbackDocCryptId(cryptId)
      form.resetDirty(values)
      return cryptId
    } finally {
      setIsSaving(false)
    }
  }

  const onSubmit = form.onSubmit(async (values) => {
    // We consider that if doc exists, it has a file.
    const hasFile = !!docCryptId || !!form.values.file

    // These 2 conditions are defensive code to avoid errors
    if (!hasFile) {
      setDropError('Please upload document')
      return
    }

    if (!form.isDirty()) {
      showNotification({
        title: 'Document is in sync',
        message: 'There are no changes to be saved',
        color: 'primary',
      })
      return // Skip if nothing to do
    }

    await save(values)

    showNotification({
      title: 'Document saved!',
      message: 'The changes were successfully saved.',
      color: 'primary',
    })

    if (docDetailState === 'modal') closeDocDetail()
  })

  return { save, onSubmit }
}
