import { Button, Group, Menu, Stack, Text, TextInput, ThemeIcon } from '@mantine/core'
import { type UseListStateHandlers } from '@mantine/hooks'
import { IconCalendar, IconPlus, IconTrash } from '@tabler/icons-react'
import React from 'react'
import { omit } from 'underscore'
import { MultiSelectCreatable } from '~/client/components/multi-select-creatable'
import {
  DatePickerInputUTC,
  DateRangePickerInputUTC,
} from '~/client/components/util/date-picker-utc'
import { zIndex } from '~/client/components/z-index'
import { hoverAppearDelay150msClasses } from '~/client/lib/css-util.css'
import { objectEntriesTypesafe } from '~/common/util'

interface OptionsFilterConfig {
  label: string
  type: 'options'
  options: { label: string; value: string }[]
}

interface DateFilterConfig {
  label: string
  type: 'date'
}
interface TextFilterConfig {
  label: string
  type: 'text'
}

interface DocFilterDefinitions {
  docTitle: TextFilterConfig
  docPartyName: TextFilterConfig
  docDate: DateFilterConfig
  docType: OptionsFilterConfig
}

interface RelationFilterDefinitions {
  relationPartyName: TextFilterConfig
  relationStartDate: DateFilterConfig
  relationEndDate: DateFilterConfig
  relationType: OptionsFilterConfig
}

export interface FilterDefinitions
  extends Partial<DocFilterDefinitions>,
    Partial<RelationFilterDefinitions> {}

export type DateFilterValue =
  | { operator: 'before' | 'since'; value: Date | null }
  | { operator: 'rangeInclusive'; from: Date | null; to: Date | null }

interface FilterValueMap {
  date: DateFilterValue
  text: string
  options: string[]
}

type InferValue<T> = T extends { type: infer U }
  ? U extends keyof FilterValueMap
    ? FilterValueMap[U]
    : never
  : never

export type FilterValue = Exclude<
  {
    // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
    [K in keyof FilterDefinitions]: {
      field: K
      value?: InferValue<FilterDefinitions[K]>
    } & FilterDefinitions[K]
  }[keyof FilterDefinitions],
  undefined
>

// Generic to avoid casting string literals
interface DropdownMenuProps<T> {
  items: { label: string; value: T }[]
  onSelect: (value: T) => void
  disabled?: boolean
}

const DropdownMenu = <T,>({
  items,
  onSelect,
  children,
  disabled,
}: React.PropsWithChildren<DropdownMenuProps<T>>) => {
  return (
    <Menu disabled={disabled} shadow='md' width={150} position='bottom-start' zIndex={zIndex.modal}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        {items.map((item) => (
          <Menu.Item onClick={() => onSelect(item.value)} key={item.label}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

const INPUT_WIDTH = 250

interface FilterRowProps {
  filterConfig: FilterDefinitions
  filterValue: FilterValue | null
  setFilterValue: (value: FilterValue) => void
  onRemove?: () => void
}

const FilterRow: React.FC<FilterRowProps> = ({
  filterConfig,
  filterValue,
  setFilterValue,
  onRemove,
}) => {
  const filterConfigList = objectEntriesTypesafe(filterConfig)

  const items = filterConfigList.flatMap(([key, value]) =>
    value ? [{ value: key, label: value.label }] : []
  )

  return (
    <Group className={hoverAppearDelay150msClasses.hover}>
      <DropdownMenu
        disabled={filterConfigList.length === 0}
        items={items}
        onSelect={(selected) => {
          const config = filterConfig[selected]
          if (!config) throw new Error('Invalid option selected')
          // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
          const value = {
            ...config,
            field: selected,
            ...(config.type === 'date' ? { value: { operator: 'since' } } : {}),
          } as FilterValue

          setFilterValue(value)
        }}
      >
        <Button
          disabled={filterConfigList.length === 0 && !filterValue}
          leftSection={
            !filterValue && (
              <ThemeIcon size='sm'>
                <IconPlus size={24} />
              </ThemeIcon>
            )
          }
        >
          {filterValue?.label ?? 'Add Filter'}
        </Button>
      </DropdownMenu>

      {filterValue?.type === 'date' && (
        <DropdownMenu
          items={[
            { label: 'Before', value: 'before' as const },
            { label: 'Since', value: 'since' as const },
            { label: 'Range', value: 'rangeInclusive' as const },
          ]}
          onSelect={(operator) => {
            const value = {
              ...filterValue,
              value: { operator, from: null, to: null, value: null },
            }

            setFilterValue(value)
          }}
        >
          <Button>{filterValue.value?.operator}</Button>
        </DropdownMenu>
      )}

      {filterValue?.type === 'date' &&
        (filterValue.value?.operator === 'rangeInclusive' ? (
          <DateRangePickerInputUTC
            value={[filterValue.value.from, filterValue.value.to]}
            onChange={(dateRange) => {
              const value = {
                ...filterValue,
                value: {
                  operator: 'rangeInclusive' as const,
                  from: dateRange[0],
                  to: dateRange[1],
                },
              }

              setFilterValue(value)
            }}
            placeholder='Select date range'
            firstDayOfWeek={0}
            leftSection={<IconCalendar size={16} />}
            miw={INPUT_WIDTH}
            maw={INPUT_WIDTH * 2}
            popoverProps={{ zIndex: zIndex.modal }}
          />
        ) : (
          <DatePickerInputUTC
            value={filterValue.value?.value}
            onChange={(date) =>
              filterValue.value?.operator &&
              filterValue.value.operator !== 'rangeInclusive' &&
              setFilterValue({
                ...filterValue,
                value: { ...filterValue.value, value: date },
              })
            }
            placeholder='Select date'
            firstDayOfWeek={0}
            allowDeselect={false}
            leftSection={<IconCalendar size={16} />}
            w={INPUT_WIDTH}
            popoverProps={{ zIndex: zIndex.modal }}
          />
        ))}

      {filterValue?.type === 'options' && (
        <>
          <Text>IS</Text>
          <MultiSelectCreatable
            creatable={false}
            value={filterValue.value}
            onChange={(value) => setFilterValue({ ...filterValue, value })}
            data={filterValue.options}
            pillsInputFieldProps={{ placeholder: 'Select one or more options' }}
            pillsInputProps={{ miw: INPUT_WIDTH }}
            pillsSeparator={<Text size='sm'>or</Text>}
            comboboxProps={{ zIndex: zIndex.modal }}
          />
        </>
      )}

      {filterValue?.type === 'text' && (
        <>
          <Text>CONTAINS</Text>
          <TextInput
            value={filterValue.value}
            onChange={(event) =>
              setFilterValue({ ...filterValue, value: event.currentTarget.value })
            }
            w={INPUT_WIDTH}
            placeholder='Text fragment'
          />
        </>
      )}

      {onRemove && (
        <ThemeIcon
          color='inactive'
          className={hoverAppearDelay150msClasses.appear}
          onClick={onRemove}
          style={{ cursor: 'pointer' }}
          size='sm'
        >
          <IconTrash />
        </ThemeIcon>
      )}
    </Group>
  )
}

interface FiltersCompProps {
  filterConfig: FilterDefinitions
  filters: FilterValue[]
  filtersHandlers: UseListStateHandlers<FilterValue>
}

export const FiltersComp: React.FC<FiltersCompProps> = ({
  filterConfig,
  filtersHandlers,
  filters,
}) => {
  const remainingFiltersConfig = omit(
    filterConfig,
    filters.map((filter) => filter.field)
  )

  return (
    <Stack>
      {filters.map((value, i) => (
        <FilterRow
          filterValue={value}
          setFilterValue={(newValue) => filtersHandlers.setItem(i, newValue)}
          key={i}
          onRemove={() => filtersHandlers.remove(i)}
          filterConfig={remainingFiltersConfig}
        />
      ))}
      {/* Fixed Add filter row */}
      <FilterRow
        filterValue={null}
        setFilterValue={(newRow) => filtersHandlers.append(newRow)}
        filterConfig={remainingFiltersConfig}
      />
    </Stack>
  )
}
