import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateSafe } from '~/common/schema/relation'
import { ZAugmentedSafe } from '~/common/schema/relation'

export const SafeMetadataTable: React.FC<DetailTableProps<ZUpdateSafe, ZAugmentedSafe>> = ({
  data,
  update,
  size,
}) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.party.mkDisplayProps(data, update, 'party')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.startDate.mkDisplayProps(data, update, 'startDate')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.endDate.mkDisplayProps(data, update, 'endDate')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.investment.mkDisplayProps(data, update, 'investment')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.valuationCap.mkDisplayProps(
          data,
          update,
          'valuationCap'
        )}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedSafe.shape.discount.mkDisplayProps(data, update, 'discount')}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
