import type { UseTRPCQueryResult } from '@trpc/react-query/shared'
import { useRouter } from 'next/router'
import React from 'react'
import { create } from 'zustand'
import { hooks, useCorpCryptId, useJwtStore } from '~/client/lib/hooks/index'
import { GenericErrorHandler } from '~/common/error-handler'
import type { ZIntegrationType } from '~/common/schema/integration'
import { type ZCreateIntegration } from '~/common/schema/integration'
import type { ZOAuthAccessTokens } from '~/common/schema/integration/docusign'
import type { OmitUnion } from '~/common/util-types'

export type MinimalIntegration = OmitUnion<ZCreateIntegration, 'corpCryptId'>

interface IntegrationAccountsRtn {
  data: MinimalIntegration[]
  isLoading: boolean
}

const useHandleIntegrationOAuthError = () => {
  const router = useRouter()
  const { mkCurrentCorpRoute } = useCorpCryptId()
  return ({
    message,
    cause,
    notification,
  }: {
    message: string
    cause?: unknown
    notification: boolean
  }) => {
    const { message: errorMessage, sentryEventId } = GenericErrorHandler.createAndCapture(message, {
      cause,
    })
    const redirectRoute = mkCurrentCorpRoute('integrations')
    if (notification) {
      const errorQueryString = new URLSearchParams({
        sentryEventId,
        errorMessage,
      }).toString()

      return router.push(`${redirectRoute}?${errorQueryString}`)
    }
    return router.push(redirectRoute)
  }
}

interface DocusignAuthCodeStore {
  /** Authorization Code returned by OAuth flow as a query parameter. We use this
   * as a query key for the accessToken since we only want to generate a single
   * token per code.  */
  code?: string
  setCode: (code: string) => void
}

export const useDocusignAuthCodeStore = create<DocusignAuthCodeStore>()((set) => ({
  setCode: (code) => set({ code }),
}))

/** Returns the Authorization Code from the store, and tries to update it with the code in
 * the URL. If there is no code in either, this handles the error.*/
const useOAuthCode = (): string | undefined => {
  const router = useRouter()
  const { code, ...otherQueryParams } = router.query
  const handleIntegrationOAuthError = useHandleIntegrationOAuthError()

  const storedCode = useDocusignAuthCodeStore((state) => state.code)
  const setStoredCode = useDocusignAuthCodeStore((state) => state.setCode)

  React.useEffect(() => {
    if (!code && !storedCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleIntegrationOAuthError({
        message: 'No code in URL and code not stored in the state',
        notification: false,
      })
    }
    if (typeof code === 'string') {
      setStoredCode(code)
      // remove the code from the URL so users can share it
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace(
        {
          query: otherQueryParams,
        },
        undefined,
        { shallow: true, scroll: false }
      )
    }
  }, [code, router, otherQueryParams, handleIntegrationOAuthError, storedCode, setStoredCode])
  return storedCode
}

const useDocusignAccessToken = (
  code: string | undefined
): UseTRPCQueryResult<ZOAuthAccessTokens, unknown> => {
  return hooks.trpc().integration.docusign.exchangeCodeForToken.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { code: code! },
    {
      enabled: !!code,
      // we only want to refetch the token when the code changes.
      meta: { noGlobalInvalidation: true },
    }
  )
}

const useDocusignAccounts = (code: string | undefined): IntegrationAccountsRtn => {
  const accessTokenResult = useDocusignAccessToken(code)
  const { accessToken } = accessTokenResult.data ?? {}

  const docusignUserInfoResult = hooks.trpc().integration.docusign.getUserInfo.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { accessToken: accessToken! },
    // We only want to re-run this query when the access token changes, not when
    // the user triggers mutations on the app.
    {
      enabled: !!accessToken,
      meta: { noGlobalInvalidation: true },
      // Store our JWT that validates that the user has access to these accounts
      onSuccess: ({ aerialJwt }) => {
        useJwtStore.setState({ aerialDocusignJwt: aerialJwt })
      },
    }
  )
  const accountsWithUserId = !docusignUserInfoResult.data?.userInfo.accounts
    ? []
    : docusignUserInfoResult.data.userInfo.accounts.map((account) => ({
        ...account,
        userId: docusignUserInfoResult.data.userInfo.sub,
        type: 'docusign' as const,
      }))

  return { data: accountsWithUserId, isLoading: !accessToken || docusignUserInfoResult.isLoading }
}

const useAdobeSignAccessToken = (
  code: string | undefined
): UseTRPCQueryResult<ZOAuthAccessTokens, unknown> => {
  return hooks.trpc().integration.adobeSign.exchangeCodeForToken.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { code: code! },
    {
      enabled: !!code,
      // we only want to refetch the token when the code changes.
      meta: { noGlobalInvalidation: true },
    }
  )
}

const useAdobeSignAccount = (code: string | undefined): IntegrationAccountsRtn => {
  const accessTokenResult = useAdobeSignAccessToken(code)
  const { accessToken, refreshToken } = accessTokenResult.data ?? {}

  const adobeSignUserInfoResult = hooks
    .trpc()
    .integration.adobeSign.getAccountInfo.useQueryWithCorp(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { accessToken: accessToken! },
      // We only want to re-run this query when the access token changes, not when
      // the user triggers mutations on the app.
      {
        enabled: !!accessToken,
        meta: { noGlobalInvalidation: true },
      }
    )

  return {
    data:
      adobeSignUserInfoResult.data && refreshToken
        ? [{ type: 'adobeSign', ...adobeSignUserInfoResult.data, oAuthRefreshToken: refreshToken }]
        : [],
    isLoading: !accessToken || adobeSignUserInfoResult.isLoading,
  }
}

/**
 * Gets the OAuth token from the URL or the store, and calls the respective
 * integration procedure to get an access token and fetch account information.
 * Uses react-query to avoid executing multiple times.
 * @param type
 */
export const useIntegrationAccounts = (type: ZIntegrationType): IntegrationAccountsRtn => {
  const code = useOAuthCode()

  const docusignAccounts = useDocusignAccounts(type === 'docusign' ? code : undefined)
  const adobeSignAccounts = useAdobeSignAccount(type === 'adobeSign' ? code : undefined)

  switch (type) {
    case 'docusign':
      return docusignAccounts
    case 'adobeSign':
      return adobeSignAccounts
  }
}
