import { Button, ScrollArea, Stack } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import React from 'react'
import type { FilterValue } from '~/client/components/filters/filters-comp'
import { FiltersComp } from '~/client/components/filters/filters-comp'
import type { FilterProps } from '~/client/components/filters/store'
import { useFilterStore } from '~/client/components/filters/store'
import {
  convertFiltersArrayToFilters,
  convertFiltersToFiltersArray,
  mkDocFilterConfig,
  mkRelationFilterConfig,
} from '~/client/components/filters/utils'
import { BaseModal } from '~/client/components/modals'
import { zIndex } from '~/client/components/z-index'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search'
import { ZAugmentedDoc, docTypeMap } from '~/common/schema'
import { ZAugmentedRelation, typeAugmentedRelationMap } from '~/common/schema/relation'

export const FiltersModalComp: React.FC<FilterProps> = ({
  mode,
  queryObj,
  setQueryObj,
  allowedTypes,
}) => {
  const { docFilters, relationFilters } = splitTypeAndTextQueries(queryObj)
  const onClose = useFilterStore((state) => state.closeModal)

  const filterConfig = React.useMemo(() => {
    const allowedDocTypes = allowedTypes?.filter(ZAugmentedDoc.isType) ?? ZAugmentedDoc.types
    const allowedRelationTypes =
      allowedTypes?.filter(ZAugmentedRelation.isType) ?? ZAugmentedRelation.types

    const docTypeOptions = allowedDocTypes.map((option) => ({
      value: option,
      label: docTypeMap[option].short,
    }))
    const relationTypeOptions = allowedRelationTypes.map((option) => ({
      value: option,
      label: typeAugmentedRelationMap[option].display,
    }))

    switch (mode) {
      case 'DOCUMENT':
        return mkDocFilterConfig(docTypeOptions)
      case 'RELATION':
        return mkRelationFilterConfig(relationTypeOptions)
      case 'RELATION_AND_DOCUMENT':
        return Object.assign(
          mkRelationFilterConfig(relationTypeOptions, 'Relation'),
          mkDocFilterConfig(docTypeOptions, 'Doc')
        )
    }
  }, [allowedTypes, mode])

  const [filters, filtersHandlers] = useListState<FilterValue>(
    convertFiltersToFiltersArray({ filterConfig, docFilters, relationFilters })
  )

  return (
    <Stack justify='space-between' h='100%'>
      <ScrollArea h='100%'>
        <FiltersComp
          filters={filters}
          filtersHandlers={filtersHandlers}
          filterConfig={filterConfig}
        />
      </ScrollArea>
      <Button
        onClick={() => {
          setQueryObj(convertFiltersArrayToFilters(filters, queryObj))
          onClose()
        }}
        style={{ alignSelf: 'end' }}
        variant='filled'
        w='fit-content'
      >
        Done
      </Button>
    </Stack>
  )
}

export const FiltersModal: React.FC = () => {
  const modalState = useFilterStore((state) => state.filterState)
  const onClose = useFilterStore((state) => state.closeModal)

  return (
    <BaseModal
      size='xl'
      styles={{ body: { height: '70vh' } }}
      onClose={onClose}
      opened={modalState.state === 'opened'}
      title='Filter by'
      closeOnEscape={false}
      zIndex={zIndex.modal}
      withinPortal
      closeOnClickOutside={false}
    >
      {modalState.state === 'opened' && <FiltersModalComp {...modalState.props} />}
    </BaseModal>
  )
}
