import { NumberInput, Text } from '@mantine/core'
import type { NumberInputProps } from '@mantine/core'
import * as React from 'react'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import { mkFormNumberInputProps } from '~/client/lib/util'
import type {
  AugmentedMetadataNumber,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
} from '~/common/schema'
import type { InlineCommonProps } from './base'
import { InlineBaseInput } from './base'

interface InlinePriceInputProps
  extends NumberInputProps,
    InlineCommonProps<AugmentedMetadataPrice2> {
  metadataSchema:
    | typeof AugmentedMetadataPrice2
    | typeof AugmentedMetadataPrice4
    | typeof AugmentedMetadataNumber
    | typeof AugmentedMetadataPercentage
  leftText?: string
}
export const InlineNumericInput: React.FC<InlinePriceInputProps> = ({
  update,
  initialValue,
  docs,
  metadataSchema,
  alignment,
  noWrap,
  leftText,
  ...props
}) => {
  return (
    <InlineBaseInput
      initialValues={initialValue}
      schema={metadataSchema.pickValueSchema}
      update={update && (({ metadata }) => update(metadata))}
      display={metadataSchema.display(initialValue?.value)}
      docs={docs}
      alignment={alignment}
      valueIsFilled={!!initialValue?.value}
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => {
        return (
          <>
            {/* If $ was in formatter, it wouldn't be possible to clear the input unless $ is also cleared. */}
            {leftText && <Text>{leftText}</Text>}
            <NumberInput
              {...props}
              {...mkFormNumberInputProps(formGetInputProps('value'))}
              autoFocus
              min={metadataSchema.minValue}
              {...('maxValue' in metadataSchema ? { max: metadataSchema.maxValue } : {})}
              // Be default, the input will only be clamped only on blur
              // This value makes it impossible to write values outside min/max
              clampBehavior='strict'
              decimalScale={metadataSchema.precision}
              styles={{
                input: { width: inlineInputWidth },
              }}
            />
          </>
        )
      }}
    />
  )
}
