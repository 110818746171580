import { Table } from '@mantine/core'
import React from 'react'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { DocComponentsLayout, MissingAndDocsView } from '~/client/components/doc-view'
import { DetailLayout, DetailSkeleton } from '~/client/components/relation/detail'
import { ActionsButtons } from '~/client/components/relation/doc-button'
import { RedFlagsSection } from '~/client/components/relation/red-flag'
import type { UseSetLinkMutationResult } from '~/client/components/relation/util'
import { useDocSearchModal } from '~/client/components/relation/util'
import { NoDataComp } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { MetadataRow } from '~/client/components/util/metadata'
import {
  hooks,
  useCorpCryptId,
  useCurrentCorpAuth,
  useOptimisticCorpUpdate,
} from '~/client/lib/hooks'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import { optimisticSetLink } from '~/client/lib/util'
import {
  type EnhancedCorp,
  type RedFlagInfo,
  enhanceCorp,
  enhanceDoc,
  getCorpRedFlagInfo,
} from '~/common/enhance'
import { navDropRoutePathMap } from '~/common/route/top-route'
import type { ZDocType } from '~/common/schema'
import { ZAugmentedCorp, type ZUpdateCorp } from '~/common/schema'

export const CorporateSummaryTable: React.FC<{
  data: EnhancedCorp
  update: (data: Partial<ZUpdateCorp>) => Promise<unknown>
  size?: 'sm' | 'lg'
}> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.name.mkDisplayProps(data, update, 'name')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.startDate.mkDisplayProps(data, update, 'startDate')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.address.mkDisplayProps(data, update, 'address')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.ein.mkDisplayProps(data, update, 'ein')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.authorizedShares.mkDisplayProps(
          data,
          update,
          'authorizedShares'
        )}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedCorp.shape.lawFirm.mkDisplayProps(data, update, 'lawFirm')}
        size={size}
      />
    </Table.Tbody>
  </Table>
)

const CorpDocsAndButtons: React.FC<{
  enhancedCorp: EnhancedCorp
  redFlagInfo: RedFlagInfo | undefined
  setLink: UseSetLinkMutationResult
  openSearchModal: (type?: ZDocType) => void
}> = ({ setLink, enhancedCorp, redFlagInfo, openSearchModal }) => {
  const allowedTypes = ZAugmentedCorp.supportedTypes
  const parentIndexList = navDropRoutePathMap['organizational-info'].indexList
  const enhancedDocs = enhancedCorp.docs.map((doc) => enhanceDoc(doc, parentIndexList))

  const openDocDetail = useDocDetailViewStore((state) => state.openModal)

  const linkOptions = { type: 'corp' } as const

  return (
    <DocComponentsLayout
      headerElements={
        <ActionsButtons
          openDocDetail={() => openDocDetail({ allowedTypes, linkOptions })}
          openSearchModal={() => openSearchModal()}
        />
      }
    >
      <MissingAndDocsView
        allowedTypes={allowedTypes}
        docs={enhancedDocs}
        missingDocs={redFlagInfo?.missingDocs}
        link={(type) => openSearchModal(type)}
        setLink={setLink}
        upload={(type) => openDocDetail({ preselectedType: type, allowedTypes, linkOptions })}
        sourceCryptIds={enhancedCorp.sourceCryptIds}
        linkOptions={linkOptions}
      />
    </DocComponentsLayout>
  )
}

const CorpDetail: React.FC<{
  enhancedCorp: EnhancedCorp
  redFlagInfo: RedFlagInfo | undefined
}> = ({ enhancedCorp, redFlagInfo }) => {
  const { corpCryptId } = useCorpCryptId()
  const utils = hooks.trpc().useContext()

  const setLink = hooks.trpc().corp.setLink.useMutationWithCorp({
    ...optimisticMutationOptions(utils.corp.read, { corpCryptId }, (input, oldData) => {
      if (!oldData) return oldData

      return optimisticSetLink(oldData, input.docCryptId, input.linkValue)
    }),
  })

  const { modal, openSearchModal } = useDocSearchModal(enhancedCorp, setLink)

  const updateCorp = useOptimisticCorpUpdate()

  return (
    <>
      <DetailLayout
        redFlagInfo={redFlagInfo}
        testId='org-info'
        data={enhancedCorp}
        metadataComponent={
          <CorporateSummaryTable
            update={(data) => updateCorp.mutateAsync({ data })}
            data={enhancedCorp}
          />
        }
        documentsComponent={
          <CorpDocsAndButtons
            redFlagInfo={redFlagInfo}
            enhancedCorp={enhancedCorp}
            openSearchModal={openSearchModal}
            setLink={setLink}
          />
        }
        redFlagsComponent={
          redFlagInfo && (
            <RedFlagsSection
              data={enhancedCorp}
              redFlagInfo={redFlagInfo}
              openSearchModal={openSearchModal}
            />
          )
        }
      />
      {modal}
    </>
  )
}

export const OrganizationalInfo: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const { corpCryptId } = useCorpCryptId()
  const corpQueryResult = hooks.trpc().corp.read.useQueryWithCorp()
  const redFlagQueryEnabled = !!auth && auth.level !== 'investor'
  const redFlagsQueryResult = hooks
    .trpc()
    .redFlags.get.byPrimaryIds.useQueryWithCorp(
      { primaryCryptIds: [corpCryptId] },
      { enabled: redFlagQueryEnabled }
    )

  const queryResult = {
    isFetching: corpQueryResult.isFetching || redFlagsQueryResult.isFetching,
    isLoading: corpQueryResult.isLoading || (redFlagQueryEnabled && redFlagsQueryResult.isLoading),
    isError: corpQueryResult.isError || redFlagsQueryResult.isError,
    error: corpQueryResult.error || redFlagsQueryResult.error,
  }

  const enhancedCorp = corpQueryResult.data ? enhanceCorp(corpQueryResult.data) : undefined
  const redFlagInfo =
    redFlagsQueryResult.data &&
    enhancedCorp &&
    getCorpRedFlagInfo(enhancedCorp, redFlagsQueryResult.data)

  return (
    <LoadingErrorComp
      queryResult={queryResult}
      skeleton={<DetailSkeleton metadataRows={5} docsAmount={7} />}
    >
      {!corpQueryResult.data || !enhancedCorp ? (
        <NoDataComp message='No Corporate Information found' />
      ) : (
        <CorpDetail redFlagInfo={redFlagInfo} enhancedCorp={enhancedCorp} />
      )}
    </LoadingErrorComp>
  )
}
