import type { CryptId } from '@cryptid-module'
import { useQueryClient } from '@tanstack/react-query'
import pMap from 'p-map'
import React from 'react'
import { useMultiDocStore } from '~/client/components/multi-doc-drop/state'
import { computeSHA256, invalidateQueries } from '~/client/lib/util'
import { extractDocusignIdFromPdf } from '~/common/extract-docusign-id'
import { checkPdfCorrupt } from '~/common/pdfjs'
import type {
  ZCreateDoc,
  ZDocCryptId,
  ZLinkOptions,
  ZManualDocUploadSource,
  ZUpdateDoc,
} from '~/common/schema'
import { hooks } from './dependency-injection/interface'

const UPLOAD_CONCURRENCY_LIMIT = 20

interface CreateDocInput {
  // We calculate the sha256 here
  docInfo: Omit<ZCreateDoc, 'sha256'>
  path: string
  docCryptId?: never
  file: File
  linkOptions?: ZLinkOptions
}

interface UpdateDocInput {
  docInfo: ZUpdateDoc
  path?: never
  docCryptId: CryptId
  file?: File
  linkOptions?: never
}

type UseCreateDocAndUploadInput = CreateDocInput | UpdateDocInput

const useUpdateUploadStateWrapper = ({
  onError,
  onIncrementStatus,
}: Pick<CreateDocAndUploadCallbacks, 'onError' | 'onIncrementStatus'>) => {
  return React.useCallback(
    async <TData>(fn: () => Promise<TData>, path: string) => {
      try {
        const result = await fn()
        onIncrementStatus?.(path)
        return result
      } catch (error) {
        if (error instanceof Error) {
          onError?.(path, error)
        }
        throw error
      }
    },
    [onError, onIncrementStatus]
  )
}

interface UseCreateDocAndUploadSingle {
  /**
   * Create or updates doc and uploads file and thumbnail to S3 while
   * maintaining upload state
   * @param input
   * @returns
   */
  createDocAndUpload: (input: UseCreateDocAndUploadInput) => Promise<ZDocCryptId>
}
interface UseCreateDocAndUploadMulti {
  /**
   * Runs createDocAndUpload in parallel with pMap at a concurrency limit and
   * invalidates the trpc context after all uploads are completed.
   * @param inputs
   * @returns
   */
  createDocAndUploadBatch: (inputs: UseCreateDocAndUploadInput[]) => Promise<ZDocCryptId[]>
}

interface CreateDocAndUploadCallbacks {
  onFinish?: (sha256: string, cryptId: CryptId) => void
  onError?: (path: string, error: Error) => void
  onIncrementStatus?: (path: string) => void
}

const useCreateDocAndUploadRaw = (
  sourceType: ZManualDocUploadSource['type'],
  { onFinish, ...callbacks }: CreateDocAndUploadCallbacks
) => {
  const skipInvalidationMeta = {
    meta: {
      skipInvalidate: true,
    },
  } as const

  const docCreate = hooks.trpc().doc.create.useMutationWithCorp(skipInvalidationMeta)
  const docUpdate = hooks.trpc().doc.update.useMutationWithCorp(skipInvalidationMeta)
  const docDelete = hooks.trpc().doc.delete.useMutationWithCorp(skipInvalidationMeta)

  const upload = hooks.useS3Upload()
  const generateThumbnail = hooks.useGenerateThumbnail()
  const updateUploadStateWrapper = useUpdateUploadStateWrapper(callbacks)

  return React.useCallback(
    async ({ docInfo, path, file, docCryptId, linkOptions }: UseCreateDocAndUploadInput) => {
      // update
      if (docCryptId) {
        return docUpdate.mutateAsync({
          doc: docInfo,
          cryptId: docCryptId,
        })
      }

      const buffer = await file.arrayBuffer()
      const pdfUnit8Array = new Uint8Array(buffer)

      // check if pdf is corrupted for consistency, as it is not too expensive
      // to do. It is somewhat redundant, as we already are notified of corrupt
      // documents when trying to generate the thumbnail.
      await updateUploadStateWrapper(async () => {
        const corruptResult = await checkPdfCorrupt({ data: pdfUnit8Array })
        if (corruptResult.isCorrupted) {
          throw new Error('PDF is corrupted', { cause: corruptResult.cause })
        }
      }, path)

      // creation
      const sha256 = await computeSHA256(file)
      const docusignEnvelopeIdPdf = extractDocusignIdFromPdf(buffer)
      const result = await updateUploadStateWrapper(
        () =>
          docCreate.mutateAsync({
            doc: { ...docInfo, sha256, source: { type: sourceType, docusignEnvelopeIdPdf } },
            linkOptions,
          }),
        path
      )

      // Upload file and generate thumbnail
      try {
        await Promise.all([
          updateUploadStateWrapper(
            () =>
              upload.mutateAsync({
                url: result.file,
                file: pdfUnit8Array,
                contentType: 'application/pdf',
              }),
            path
          ),

          updateUploadStateWrapper(() => generateThumbnail.mutateAsync({ buffer }), path).then(
            (thumbnail) =>
              updateUploadStateWrapper(
                () =>
                  upload.mutateAsync({
                    url: result.thumb,
                    file: new Uint8Array(thumbnail),
                    contentType: 'image/png',
                  }),
                path
              )
          ),
        ])
        onFinish?.(sha256, result.cryptId)
      } catch (error) {
        try {
          await docDelete.mutateAsync({ cryptId: result.cryptId })
        } catch {
          // this can fail if there is no connection to the s3 server
          // in that case, only the document in the DB will be deleted.
          // The user will be notified, so we don't need to do anything here
        }
      }

      return result
    },
    [
      updateUploadStateWrapper,
      docUpdate,
      docCreate,
      sourceType,
      onFinish,
      upload,
      generateThumbnail,
      docDelete,
    ]
  )
}

export const useCreateDocAndUploadSingle = (): UseCreateDocAndUploadSingle => {
  const queryClient = useQueryClient()
  const createDocAndUploadRaw = useCreateDocAndUploadRaw('manual-single-upload', {})

  const createDocAndUpload = React.useCallback(
    async (input: UseCreateDocAndUploadInput) => {
      const result = await createDocAndUploadRaw(input)
      // Invalidate here since we skipped invalidations in upload
      await invalidateQueries(queryClient)
      return result
    },
    [createDocAndUploadRaw, queryClient]
  )

  return {
    createDocAndUpload,
  }
}

export const useCreateDocAndUploadMulti = (): UseCreateDocAndUploadMulti => {
  const queryClient = useQueryClient()
  const setDuplicatesForUploadedFile = useMultiDocStore(
    (state) => state.setDuplicatesForUploadedFile
  )
  const incrementDocUploadState = useMultiDocStore((state) => state.incrementDocUploadState)
  const setDocErrorStatus = useMultiDocStore((state) => state.setDocErrorStatus)

  const createDocAndUploadRaw = useCreateDocAndUploadRaw('manual-bulk-upload', {
    onFinish: setDuplicatesForUploadedFile,
    onError: (path, error) => setDocErrorStatus(path, error.message),
    onIncrementStatus: incrementDocUploadState,
  })

  const createDocAndUploadBatch = React.useCallback(
    async (inputs: UseCreateDocAndUploadInput[]) => {
      const result = await pMap(inputs, createDocAndUploadRaw, {
        concurrency: UPLOAD_CONCURRENCY_LIMIT,
      })
      // Invalidate here since we skipped invalidations in upload
      await invalidateQueries(queryClient)
      return result
    },
    [createDocAndUploadRaw, queryClient]
  )

  return {
    createDocAndUploadBatch,
  }
}
