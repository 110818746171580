import { appendPath, mkUrlWithSearchParams } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'

export const ADOBE_SIGN_SCOPES = ['agreement_read:account', 'user_read:self']

export const mkAdobeSignOAuthUrl = (stateNonce: string, customRedirectUri?: string): string => {
  const redirectUri =
    customRedirectUri ??
    appendPath(zenvCommon.NEXT_PUBLIC_REDIRECT_URL, zenvCommon.NEXT_PUBLIC_ADOBE_SIGN_CALLBACK_PATH)

  return mkUrlWithSearchParams('https://secure.na1.adobesign.com/public/oauth/v2', {
    redirect_uri: redirectUri,
    response_type: 'code',
    client_id: zenvCommon.NEXT_PUBLIC_ADOBE_SIGN_APP_ID,
    scope: ADOBE_SIGN_SCOPES.join(' '),
    state: stateNonce,
  })
}
