import type { TextInputProps } from '@mantine/core'
import { TextInput } from '@mantine/core'
import * as React from 'react'
import { z } from 'zod'
import { inlineInputWidth } from '~/client/components/util/inline/util'
import type { RelationAutofill } from '~/common/enhance'
import { ZParty } from '~/common/schema/party'
import type { InlineCommonProps } from './base'
import { InlineBaseInput } from './base'

const ZPartyForm = z.object({
  name: z.string(),
  email: z
    .string()
    .email()
    .optional()
    .or(z.literal(''))
    .transform((e) => (e === '' ? undefined : e)),
})
interface InlinePartyInputProps extends TextInputProps, Omit<InlineCommonProps<ZParty>, 'docs'> {
  autofillData?: RelationAutofill['parties']
}
export const InlinePartyInput: React.FC<InlinePartyInputProps> = ({
  update,
  initialValue,
  autofillData,
  alignment,
  noWrap,
  ...props
}) => {
  const autofills = autofillData?.map((item) => ZParty.display(item))

  return (
    <InlineBaseInput
      initialValues={{
        // React complains if initially undefined value is set; use '' to silence error
        // The error triggers occasional errors in jest testing so it has to be handled
        name: initialValue?.name ?? '',
        email: initialValue?.email ?? '',
      }}
      schema={ZPartyForm}
      update={update && (({ values }) => update(values))}
      display={
        initialValue?.email ? `${initialValue.name} (${initialValue.email})` : initialValue?.name
      }
      autofills={autofills}
      autofillData={autofillData}
      valueIsFilled={!!initialValue?.name}
      alignment={alignment}
      dropOverDirection='vertical'
      noWrap={noWrap}
      dropOverContent={({ formGetInputProps }) => (
        <>
          <TextInput
            {...props}
            {...formGetInputProps('name')}
            placeholder='name'
            styles={{
              input: { width: inlineInputWidth },
            }}
          />
          <TextInput
            {...props}
            {...formGetInputProps('email')}
            placeholder='email'
            styles={{
              input: { width: inlineInputWidth },
            }}
          />
        </>
      )}
    />
  )
}
