import { Pill, Tooltip } from '@mantine/core'
import pluralize from 'pluralize'
import React from 'react'
import type { FilterEntries } from '~/client/lib/hooks/search/'
import { theme } from '~/client/lib/theme'
import type { ZDocFilters } from '~/common/schema'
import { AugmentedMetadataDate } from '~/common/schema'
import type { ZRelationFilters } from '~/common/schema/relation'
import { objectEntriesTypesafe } from '~/common/util'

export const filterFieldDisplayMap = {
  endDate: 'End Date',
  startDate: 'Start Date',
  party: 'Party Name',
  title: 'Title',
  type: 'Type',
  date: 'Date',
}

const FilterDisplayByFiltersType: React.FC<{
  filters?: ZDocFilters | ZRelationFilters
  prefix?: 'Relation' | 'Doc'
  openFilterModal: () => void
  isDoc?: boolean
}> = ({ filters, prefix, openFilterModal, isDoc }) => {
  if (!filters) return null
  // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
  const entries = objectEntriesTypesafe(filters) as FilterEntries

  const pills = entries
    .map((entry) => {
      if (!entry[1] || (Array.isArray(entry[1]) && entry[1].length === 0)) return
      const field = isDoc && entry[0] === 'startDate' ? 'date' : entry[0]
      const filterDisplay = [prefix, filterFieldDisplayMap[field]].filter(Boolean).join(' ')

      const display = (() => {
        switch (entry[0]) {
          case 'endDate':
          case 'startDate': {
            const dateDisplay =
              entry[1].operator === 'rangeInclusive'
                ? `${AugmentedMetadataDate.display(
                    entry[1].from
                  )} - ${AugmentedMetadataDate.display(entry[1].to)}`
                : `${entry[1].operator} ${AugmentedMetadataDate.display(entry[1].value)}`
            return (
              <>
                {filterDisplay}: <b>{dateDisplay}</b>
              </>
            )
          }
          case 'party':
          case 'title':
            return (
              <>
                {filterDisplay}: <b>&quot;{entry[1]}&quot;</b>
              </>
            )
          case 'type':
            return (
              <>
                {filterDisplay}:{' '}
                <Tooltip label={entry[1].join(', ')}>
                  <b>
                    {entry[1].length} {pluralize('type', entry[1].length)}
                  </b>
                </Tooltip>
              </>
            )
        }
      })()

      return (
        <Pill
          key={entry[0]}
          onClick={openFilterModal}
          size='md'
          bg={theme.colors.primary[0]}
          style={{ cursor: 'pointer' }}
        >
          {display}
        </Pill>
      )
    })
    .filter(Boolean)

  return <>{pills}</>
}

export const FilterDisplay: React.FC<{
  openFilterModal: () => void
  type: 'RELATION' | 'DOCUMENT' | 'RELATION_AND_DOCUMENT'
  filters: { docFilters?: ZDocFilters; relationFilters?: ZRelationFilters }
}> = ({ filters, openFilterModal, type }) => {
  switch (type) {
    case 'DOCUMENT':
      return (
        <FilterDisplayByFiltersType
          isDoc
          filters={filters.docFilters}
          openFilterModal={openFilterModal}
        />
      )
    case 'RELATION':
      return (
        <FilterDisplayByFiltersType
          filters={filters.relationFilters}
          openFilterModal={openFilterModal}
        />
      )
    case 'RELATION_AND_DOCUMENT':
      return (
        <>
          <FilterDisplayByFiltersType
            isDoc
            prefix='Doc'
            filters={filters.docFilters}
            openFilterModal={openFilterModal}
          />
          <FilterDisplayByFiltersType
            prefix='Relation'
            filters={filters.relationFilters}
            openFilterModal={openFilterModal}
          />
        </>
      )
  }
}
