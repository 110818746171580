import { Group, Space } from '@mantine/core'
import React, { useState } from 'react'
import {
  INTEGRATION_CARD_WIDTH,
  IntegrationCard,
  IntegrationCardSkeleton,
} from '~/client/components/integration/integration-card'
import { getNotificationErrorMsg } from '~/client/components/monitoring'
import { FullScreenMsg } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { deletePromptConfirm } from '~/client/components/util/prompt'
import { hooks } from '~/client/lib/hooks'
import type { MinimalIntegration } from '~/client/lib/hooks/integration-state'
import { useIntegrationAccounts } from '~/client/lib/hooks/integration-state'
import { displayIntegrationType } from '~/common/integration/util'
import type { ZAugmentedIntegration, ZIntegrationType } from '~/common/schema/integration'
import { zenvCommon } from '~/common/zenv-common'
import type { ModalButtonStatus } from './manage-integration-modal'

const formatAccountSubtitle = (integration: MinimalIntegration) => {
  switch (integration.type) {
    case 'docusign':
      // Displaying the id to differentiate between multiple accounts of the same user
      // The id is prefixed by a # in Docusign
      return integration.externalAccountId && `#${integration.externalAccountId}`
    case 'adobeSign':
      // Each user is limited to one account, so no need to show the id
      return ''
  }
}

interface ConnectIntegrationCardProps {
  setModalButtonStatus: React.Dispatch<React.SetStateAction<ModalButtonStatus>>
  integration: MinimalIntegration
}

export const ConnectIntegrationCard: React.FC<ConnectIntegrationCardProps> = ({
  setModalButtonStatus,
  integration,
}) => {
  // CAREFUL: We handle the state explicitly here to avoid complex query cache
  // management. We want to show different loading states depending on the
  // requests that we make which is more explicit to do this way.
  const [cardState, setCardState] = useState<'idle' | 'connecting' | 'fetching'>('idle')
  // We don't manage the loading state with the mutation since we do not want to
  // stop showing the spinner until this card changes to the remove variant
  const { mutateAsync } = hooks.trpc().integration.create.useMutationWithCorp()
  const firstTimeLoad = hooks.trpc().integration.firstTimeQueryAndUpload.useMutationWithCorp()
  const { data: userData } = hooks.useCurrentUser()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  return (
    <IntegrationCard
      title={integration.accountName}
      subtitle={formatAccountSubtitle(integration)}
      integrationType={integration.type}
      // This will make the card switch state immediately without having to wait
      // until `integration.list` resolves with the newly added integration.
      variant={cardState === 'fetching' ? 'querying' : 'connect'}
      isLoadingButton={cardState === 'connecting'}
      onClickButton={async () => {
        if (!userData) throw new Error('Current user data not found. This should never happen')
        try {
          setErrorMessage(undefined)
          setCardState('connecting')
          setModalButtonStatus('loading')
          const { cryptId } = await mutateAsync(integration)
          setCardState('fetching')
          await firstTimeLoad.mutateAsync({ cryptId })
          setModalButtonStatus('done')
        } catch (err) {
          setErrorMessage(
            getNotificationErrorMsg(err, {
              defaultMessage: `Unexpected error while connecting to your ${displayIntegrationType(
                integration.type
              )} account. Please try again`,
            })
          )
          setCardState('idle')
          setModalButtonStatus('initial')
        }
        // we do not want to set loading to false here as explained above
      }}
      errorMessage={errorMessage}
    />
  )
}

export const RemoveIntegrationCard: React.FC<{ integration: ZAugmentedIntegration }> = ({
  integration,
}) => {
  const { mutateAsync, isLoading } = hooks.trpc().integration.delete.useMutationWithCorp()

  const isMutatingQueryAndUpload = hooks.useIsIntegrationFetchingDocuments(integration.cryptId)
  return (
    <IntegrationCard
      title={integration.accountName}
      subtitle={formatAccountSubtitle(integration)}
      lastSuccessfulQuery={integration.lastSuccessfulQuery}
      integrationType={integration.type}
      variant={isMutatingQueryAndUpload ? 'querying' : 'remove'}
      isLoadingButton={isLoading}
      onClickButton={async () => {
        const confirm = await deletePromptConfirm({ itemName: 'Integration' })
        if (confirm) await mutateAsync({ cryptId: integration.cryptId })
      }}
    />
  )
}

export const ManageIntegrationComp: React.FC<{
  type: ZIntegrationType
  setModalButtonStatus: React.Dispatch<React.SetStateAction<ModalButtonStatus>>
}> = ({ setModalButtonStatus, type }) => {
  const accounts = useIntegrationAccounts(type)

  // We want to fetch all the accounts of this type for the corp, to be able to
  // determine if the callback accounts are already part of our DB or not.
  // Fetching the NEXT_PUBLIC_PAGE_QUERY_LIMIT should return all of them as real
  // users won't have 100+ integrations for this type in our DB.
  // TODO: add pagination later.
  const integrationsResult = hooks.trpc().integration.list.useQueryWithCorp({
    direction: 1,
    limit: zenvCommon.NEXT_PUBLIC_PAGE_QUERY_LIMIT,
    type,
  })

  const integrationsMap = React.useMemo(() => {
    return new Map<string, ZAugmentedIntegration>(
      integrationsResult.data?.data.map((integration) => [integration.accountId, integration])
    )
  }, [integrationsResult.data])

  return (
    // Use position:center to make it looking good in case of
    // 1. Only one integration
    // 2. Small width screens (displays one card per row)
    <Group p='sm' gap='xl' maw={INTEGRATION_CARD_WIDTH * 2 + 60} justify='center'>
      <LoadingErrorComp
        queryResult={{
          ...integrationsResult,
          isLoading: integrationsResult.isLoading || accounts.isLoading,
        }}
        skeleton={<IntegrationCardSkeleton />}
      >
        {accounts.data.length > 0 ? (
          <>
            {accounts.data.map((account) => {
              const { accountId } = account
              const integrationFromDB = integrationsMap.get(accountId)
              if (integrationFromDB) {
                return <RemoveIntegrationCard key={accountId} integration={integrationFromDB} />
              }

              return (
                <ConnectIntegrationCard
                  key={accountId}
                  integration={account}
                  setModalButtonStatus={setModalButtonStatus}
                />
              )
            })}
            {/* add this extra component to prevent last odd card centered in two columns mode*/}
            {accounts.data.length > 2 && accounts.data.length % 2 === 1 ? (
              <Space w={INTEGRATION_CARD_WIDTH} />
            ) : null}
          </>
        ) : (
          <FullScreenMsg title='No accounts to connect were found for your user' />
        )}
      </LoadingErrorComp>
    </Group>
  )
}
