import type { CryptId } from '@cryptid-module'
import Router from 'next/router'
import React, { useState } from 'react'
import { createStore } from 'zustand'
import {
  type DocDetailProp,
  type DocDetailState,
  useDocDetailViewStore,
  useDocForm,
} from '~/client/components/doc-detail/state'
import { useAutofill } from '~/client/components/doc-detail/state/autofill'
import { useDocFile } from '~/client/components/doc-detail/state/file'
import {
  promptSave as _promptSave,
  promptCloseDiscardChanges,
} from '~/client/components/doc-detail/state/prompts'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import { useAutocheck } from '~/client/lib/hooks/auto-check'
import { useConfirmLeave } from '~/client/lib/hooks/confirm-leave'
import { createContextStoreHook } from '~/client/lib/zustand-utils'
import { ZAugmentedDoc } from '~/common/schema'

interface StoreProps
  extends Pick<
      DocDetailProp,
      'isLoading' | 'allowAllTypes' | 'doc' | 'allowedTypes' | 'linkOptions' | 'preselectedType'
    >,
    Pick<
      DocDetailState,
      | 'isLoadingPDF'
      | 'pdfDataUrl'
      | 'form'
      | 'autofill'
      | 'suggestionsLoadingStates'
      | 'autocheck'
      | 'docCryptId'
      | 'setFallbackDocCryptId'
    > {}

interface MutableDocDetailState extends DocDetailState {
  setProps: (props: StoreProps) => void
}

const prepareUpdate = ({ allowedTypes, doc, ...props }: StoreProps) => ({
  ...props,
  doc,
  allowedTypes: allowedTypes ?? ZAugmentedDoc.types,
  isNewDoc: !doc,
})

const createDocDetailStore = (initialProps: StoreProps) => {
  return createStore<MutableDocDetailState>()((set, get) => ({
    ...prepareUpdate(initialProps),
    isSaving: false,
    setProps: (props) => set(prepareUpdate(props)),
    setIsSaving: (isSaving) => set({ isSaving }),
    setDropError: (dropError) => set({ dropError }),
    promptSave: () => {
      const { form, allowedTypes, doc } = get()
      const docType = form.values.type
      const allowedTypesSet = new Set(allowedTypes)
      const docTypeRecommended = allowedTypesSet.has(docType)

      return _promptSave({
        docType,
        oldDocType: doc?.type,
        docTypeRecommended,
      })
    },
  }))
}

interface DocDetailStateStore extends ReturnType<typeof createDocDetailStore> {}

const DocDetailContext = React.createContext<DocDetailStateStore | null>(null)

// Based on this pattern: https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#creating-a-context-with-react.createcontext
export const DocDetailStateProvider: React.FC = ({ children }) => {
  const form = useDocForm()
  const closeDocDetail = useDocDetailViewStore((state) => state.closeModal)

  const {
    doc,
    allowedTypes,
    linkOptions,
    isLoading,
    allowAllTypes,
    autofill: _autofill,
    preselectedType,
    enablePopulateForm,
    fallbackDocCryptId: _fallbackDocCryptId,
  } = useDocDetailViewStore((state) => state.docDetailViewState.prop ?? {})
  const [fallbackDocCryptId, setFallbackDocCryptId] = useState<CryptId | undefined>(
    _fallbackDocCryptId
  )

  const { isLoadingPDF, dataUrl: pdfDataUrl } = useDocFile({
    doc,
    file: form.values.file,
    isLoading,
  })

  const allowedTypesSet = React.useMemo(() => new Set(allowedTypes ?? []), [allowedTypes])
  const docTypesFilter = React.useCallback(
    (docType) => allowAllTypes || allowedTypesSet.has(docType),
    [allowAllTypes, allowedTypesSet]
  )

  const docCryptId = doc?.cryptId ?? fallbackDocCryptId

  const { data: auth } = useCurrentCorpAuth()
  const autocheckEnabled = !!auth?.superAdmin // Autocheck is currently only available for admins

  const { autofill, suggestionsLoadingStates, autofillWithHiddenSuggestions } = useAutofill(
    _autofill,
    docCryptId,
    {
      // Get all suggestions even if they are filled to validate autocheck
      types: autocheckEnabled || allowAllTypes || allowedTypesSet.size > 1,
      dates: autocheckEnabled || !form.values.startDate,
      emails: autocheckEnabled || !form.values.party.email,
      titles: autocheckEnabled || !form.values.title,
      parties: autocheckEnabled || !form.values.party.name,
    },
    docTypesFilter
  )

  const autocheck = useAutocheck({
    form,
    autofill,
    autofillWithHiddenSuggestions,
    enabled: autocheckEnabled,
    docCryptId,
  })

  const initialProps = React.useMemo(
    () => ({
      form,
      doc,
      allowedTypes,
      linkOptions,
      isLoadingPDF,
      pdfDataUrl,
      isLoading,
      allowAllTypes,
      autofill,
      suggestionsLoadingStates,
      autocheck,
      docCryptId,
      setFallbackDocCryptId,
      preselectedType,
      enablePopulateForm,
    }),
    [
      form,
      doc,
      allowedTypes,
      linkOptions,
      isLoadingPDF,
      pdfDataUrl,
      isLoading,
      allowAllTypes,
      autofill,
      suggestionsLoadingStates,
      autocheck,
      docCryptId,
      setFallbackDocCryptId,
      preselectedType,
      enablePopulateForm,
    ]
  )

  const store = React.useRef(createDocDetailStore(initialProps)).current

  React.useEffect(() => store.getState().setProps(initialProps), [store, initialProps])

  // Request confirmation when leaving page
  useConfirmLeave({
    condition: () => form.isDirty() && !store.getState().isSaving,
    promptClose: () => promptCloseDiscardChanges(),
  })

  // Ensures the modal is closed on route change
  React.useEffect(() => {
    const closeAndSkip = () => {
      // Do not close if we are navigating to the processing wizard, as this
      // will incorrectly close the wizard modal.
      if (Router.asPath.includes('processing-wizard')) return
      closeDocDetail({ skipRouteChange: true })
    }
    Router.events.on('routeChangeComplete', closeAndSkip)
    return () => Router.events.off('routeChangeComplete', closeAndSkip)
  }, [closeDocDetail])

  return <DocDetailContext.Provider value={store}>{children}</DocDetailContext.Provider>
}

export const { useContextStore: useDocDetailState } = createContextStoreHook(DocDetailContext)
