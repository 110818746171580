import { z } from 'zod'
import { ZAugmentedIntegrationBase } from '~/common/schema/integration/integration-base'

export const ZAdobeSignIntegration = z.object({
  type: z.literal('adobeSign'),
  accountId: z.string(),
  accountName: z.string(),
  oAuthRefreshToken: z.string(),
})
export interface ZAdobeSignIntegration extends z.infer<typeof ZAdobeSignIntegration> {}

export const ZAugmentedAdobeSignIntegration = ZAugmentedIntegrationBase.merge(ZAdobeSignIntegration)
export interface ZAugmentedAdobeSignIntegration
  extends z.infer<typeof ZAugmentedAdobeSignIntegration> {}
