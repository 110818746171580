import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { inferProcedureOutput } from '@trpc/server'
import { useMemo } from 'react'
import { sortBy } from 'underscore'
import { useCorpCryptId } from '~/client/lib/hooks'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { getMetadata } from '~/client/lib/hooks/search/util'
import { docDisplay } from '~/common/doc'
import type { AugmentedMetadata } from '~/common/schema'
import { ZAugmentedCorp, ZAugmentedDoc, getSchemaDisplayableFieldsInfo } from '~/common/schema'
import { ZEquitySummary } from '~/common/schema/relation/'
import { ZAugmentedRelation, typeAugmentedRelationMap } from '~/common/schema/relation/augmented'
import type { AppRouter } from '~/common/trpc/app-router'

const quickAnswerValuePrefixes = [
  'qa-corp-meta',
  'qa-cap-table-summary',
  'qa-relation',
  'qa-doc',
] as const
export type QuickAnswerValuePrefix = (typeof quickAnswerValuePrefixes)[number]

interface MKOptionProps
  extends Pick<QuickAnswerData, 'url' | 'docs' | 'label' | 'metadata' | 'sourceName'> {
  queryResult?: Pick<UseQueryResult<unknown>, 'isLoading' | 'isError' | 'error'>
  valuePrefix: QuickAnswerValuePrefix
  display: string | undefined | null
  listPosition: number
}

export const isQuickAnswerOptionValue = (
  value: string | undefined
): value is QuickAnswerValuePrefix => {
  const prefixCandidate = value?.split('|')[0]
  return !!(prefixCandidate && quickAnswerValuePrefixes.includes(prefixCandidate))
}

export const extractQuickAnswersValuePrefix = (
  value: string | undefined
): { prefix: QuickAnswerValuePrefix; url?: string } | null => {
  if (!value) return null
  const [prefixCandidate, restOrExtra, rest] = value.split('|')
  if (!isQuickAnswerOptionValue(prefixCandidate)) return null
  return { prefix: prefixCandidate, url: rest ? restOrExtra : undefined }
}

const mkQuickAnswersValue = (
  valuePrefix: QuickAnswerValuePrefix,
  label: string,
  sourceName: string,
  extra?: string
) => {
  const extraStr = extra ? `${extra}|` : ''
  return `${valuePrefix}|${extraStr}${label.replaceAll(' ', '-').toLowerCase()}|${sourceName}`
}

const mkQuickAnswerData = ({
  valuePrefix,
  label,
  url,
  sourceName,
  ...props
}: MKOptionProps): QuickAnswerDataWithScore => ({
  key: mkQuickAnswersValue(valuePrefix, label, sourceName, url),
  label,
  url,
  sourceName,
  ...props,
})

export interface QuickAnswerData {
  key: string
  label: string
  sourceName: string
  display: string | null | undefined
  queryResult?: Pick<UseQueryResult, 'isLoading' | 'isError' | 'error'>
  url: string
  docs?: ZAugmentedDoc[]
  metadata?: AugmentedMetadata
}

interface QuickAnswerDataWithScore extends QuickAnswerData {
  listPosition: number
}

export type QuickAnswersRtn = inferProcedureOutput<AppRouter['quickAnswers']['search']>

const useQuickAnswerData = (props: QuickAnswersRtn | undefined): QuickAnswerData[] => {
  const { labels, relations = [], docs = [] } = props ?? {}
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const corpQueryResult = hooks.trpc().corp.read.useQueryWithCorp()
  const equityDataQueryResult = hooks.trpc().relation.equity.equityData.useQueryWithCorp()

  const corpMetadataOptions = useMemo(() => {
    const { data } = corpQueryResult
    return getSchemaDisplayableFieldsInfo(ZAugmentedCorp).map(({ label, displayFn, getValue }) =>
      mkQuickAnswerData({
        queryResult: corpQueryResult,
        valuePrefix: 'qa-corp-meta',
        label,
        display: data && displayFn(data),
        // Since the labels are returned in order of relevancy and we want to show better results first,
        // add a score based on the position of the label in the returned list
        listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
        url: mkCurrentCorpRoute('organizational-info', '?tab=metadata'),
        docs: data?.docOpts.filter(Boolean),
        metadata: data && getMetadata(getValue(data)),
        sourceName: 'Organizational Info',
      })
    )
  }, [corpQueryResult, labels, mkCurrentCorpRoute])

  const capTableSummaryOptions = useMemo(() => {
    const data = equityDataQueryResult.data?.equitySummary

    return getSchemaDisplayableFieldsInfo(ZEquitySummary).map(({ label, displayFn }) =>
      mkQuickAnswerData({
        queryResult: equityDataQueryResult,
        valuePrefix: 'qa-cap-table-summary',
        label,
        display: data && displayFn(data),
        listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
        url: mkCurrentCorpRoute('cap-table', '?tab=summary'),
        sourceName: 'Cap Table',
      })
    )
  }, [equityDataQueryResult, labels, mkCurrentCorpRoute])

  const relationOptions = useMemo(() => {
    return relations.flatMap((relation) =>
      getSchemaDisplayableFieldsInfo(typeAugmentedRelationMap[relation.type]).map(
        ({ label, displayFn, getValue }) => {
          return mkQuickAnswerData({
            valuePrefix: `qa-relation`,
            label,
            display: displayFn(relation),
            listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
            url: mkCurrentCorpRoute('relation', `${relation.cryptId.idStr}?tab=metadata`),
            docs: relation.docOpts.filter(Boolean),
            metadata: getMetadata(getValue(relation)),
            sourceName: ZAugmentedRelation.displayFn(relation, false),
          })
        }
      )
    )
  }, [labels, relations, mkCurrentCorpRoute])

  const docOptions = useMemo(() => {
    const schema = getSchemaDisplayableFieldsInfo(ZAugmentedDoc)
    return docs.flatMap((doc) =>
      schema.map(({ label, displayFn }) => {
        return mkQuickAnswerData({
          valuePrefix: 'qa-doc',
          label,
          display: displayFn(doc),
          listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
          url: mkCurrentCorpRoute('doc', doc.cryptId.idStr),
          sourceName: docDisplay(doc),
        })
      })
    )
  }, [labels, docs, mkCurrentCorpRoute])

  const items = useMemo(() => {
    const allItems = [
      ...corpMetadataOptions,
      ...capTableSummaryOptions,
      ...relationOptions,
      ...docOptions,
    ]
    if (!labels) return allItems
    // Remove items which were not in `labels`
    const filtered = allItems.filter((item) => item.listPosition !== -1)
    // Return items that were on top of the list first
    return sortBy(filtered, (item) => item.listPosition)
  }, [corpMetadataOptions, capTableSummaryOptions, relationOptions, docOptions, labels])

  return items
}

interface UseQuickAnswerSearchProps extends Pick<UseQueryOptions, 'keepPreviousData'> {
  query: string[]
}

interface UseQuickAnswerSearch {
  searchResults: UseQueryResult<QuickAnswersRtn>
  data: QuickAnswerData[]
}

export const useQuickAnswerSearch = ({
  query,
  ...opts
}: UseQuickAnswerSearchProps): UseQuickAnswerSearch => {
  const enabled = query.filter(Boolean).length > 0
  const searchResults = hooks
    .trpc()
    .quickAnswers.search.useQueryWithCorp({ query }, { ...opts, enabled })
  const data = useQuickAnswerData(enabled ? searchResults.data : undefined)
  return { searchResults, data }
}
