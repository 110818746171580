import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdatePreferred } from '~/common/schema/relation'
import { ZAugmentedPreferred } from '~/common/schema/relation'

export const PreferredMetadataTable: React.FC<
  DetailTableProps<ZUpdatePreferred, ZAugmentedPreferred>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedPreferred.shape.party.mkDisplayProps(data, update, 'party')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedPreferred.shape.startDate.mkDisplayProps(data, update, 'startDate')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedPreferred.shape.shares.mkDisplayProps(data, update, 'shares')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedPreferred.shape.investment.mkDisplayProps(
          data,
          update,
          'investment'
        )}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
