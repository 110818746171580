export interface DocTypeStrings {
  long: string
  short: string
}

const typeStrings = (x: DocTypeStrings): DocTypeStrings => x

export const docTypeMap = {
  ANNUAL_REPORT: typeStrings({
    long: 'Annual Franchise Tax Report',
    short: 'Report',
  }),
  BUSINESS_LICENSE: typeStrings({
    long: 'Business License',
    short: 'License',
  }),
  REGISTERED_AGENT: typeStrings({
    long: 'Registered Agent',
    short: 'Agent',
  }),
  TAX_ID_DOCUMENT: typeStrings({
    long: 'State and Local TAX ID Document',
    short: 'Tax ID',
  }),
  // We are migrating Articles of Incorporation to Certificate of Incorporation
  ARTICLES_OF_INCORPORATION: typeStrings({
    long: 'Certificate of Incorporation',
    short: 'Incorporation',
  }),
  INCORPORATOR_CONSENT: typeStrings({
    long: 'Incorporator Consent',
    short: 'Incorporator',
  }),
  CORPORATE_BYLAWS: typeStrings({
    long: 'Bylaws',
    short: 'Bylaws',
  }),
  IRS_EIN_ASSIGNMENT_LETTER: typeStrings({
    long: 'IRS EIN Assignment Letter',
    short: 'EIN Letter',
  }),
  LEGAL_SETTLEMENT: typeStrings({
    long: 'Legal Settlement',
    short: 'Settlement',
  }),
  CERTIFICATE_GOOD_STANDING: typeStrings({
    long: 'Certificate of Good Standing',
    short: 'Good Standing',
  }),
  OTHER_CORPORATE_FILLING: typeStrings({
    long: 'Other Corporate Filling',
    short: 'Filing', // TODO: come up with better name
  }),
  VOTING_AGREEMENT: typeStrings({
    long: 'Voting Agreement',
    short: 'Voting',
  }),
  RIGHT_OF_FIRST_REFUSAL_AND_COSALE_AGREEMENT: typeStrings({
    long: 'Right of First Refusal and Cosale Agreement',
    short: 'ROFR',
  }),
  LEGAL_OPINION: typeStrings({ long: 'Legal Opinion', short: 'Opinion' }),
  INVESTOR_RIGHTS_AGREEMENT: typeStrings({
    long: 'Investor Rights Agreement',
    short: 'Investor Right',
  }),
  SECURITIES_LAW_FILING: typeStrings({
    long: 'Securities Law Filing',
    short: 'Securities',
  }),
  PREFERRED_STOCK_PURCHASE_AGREEMENT: typeStrings({
    long: 'Preferred Stock Purchase Agreement',
    short: 'Preferred Purchase',
  }),
  SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY: typeStrings({
    long: 'Simple Agreement for Future Equity',
    short: 'SAFE',
  }),
  CONVERTIBLE_NOTE: typeStrings({
    long: 'Convertible Note',
    short: 'Convertible',
  }),
  WARRANT_AGREEMENT: typeStrings({
    long: 'Warrant Agreement',
    short: 'Warrant',
  }),
  SIDE_LETTER: typeStrings({ long: 'Side Letter', short: 'Side' }),
  COMMON_STOCK_PURCHASE_AGREEMENT: typeStrings({
    long: 'Common Stock Purchase Agreement',
    short: 'Common',
  }),
  SECTION_83B_ELECTION_FORM: typeStrings({
    long: 'Section 83(b) Election Form',
    short: '83(b)',
  }),
  STOCK_OPTION_GRANT: typeStrings({
    long: 'Stock Option Grant',
    short: 'Option Grant',
  }),
  STOCKHOLDER_CONSENT: typeStrings({
    long: 'Stockholder Consent',
    short: 'Stockholder Consent',
  }),
  CONTRACTOR_AGREEMENT: typeStrings({
    long: 'Contractor Agreement',
    short: 'Contractor',
  }),
  W89: typeStrings({
    long: 'W8 / W9 Form',
    short: 'W8/W9',
  }),
  ADVISOR_AGREEMENT: typeStrings({
    long: 'Advisor Agreement',
    short: 'Advisor',
  }),
  PROPRIETARY_INFORMATION_AND_INVENTION_ASSIGNMENT: typeStrings({
    long: 'Proprietary Information and Invention Assignment',
    short: 'PIIA',
  }),
  IP_ASSIGNMENT: typeStrings({
    long: 'IP Assignment',
    short: 'IPA',
  }),
  IP_LICENSE: typeStrings({
    long: 'IP License',
    short: 'IPL',
  }),
  EMPLOYMENT_AGREEMENT: typeStrings({
    long: 'Employment Agreement',
    short: 'Employment',
  }),
  OFFER_LETTER: typeStrings({ long: 'Offer Letter', short: 'Offer' }),
  INDEMNIFICATION_AGREEMENT: typeStrings({
    long: 'Indemnification Agreement',
    short: 'Indemnification',
  }),
  BOARD_CONSENT_AND_MINUTES: typeStrings({
    long: 'Board Consent and Minutes',
    short: 'Board Consent',
  }),
  _409A_REPORT: typeStrings({
    long: '409A Valuation Report',
    short: '409A',
  }),
  STOCK_OPTION_PLAN: typeStrings({
    long: 'Equity Incentive Plan',
    short: 'Equity Plan',
  }),
  PRO_FORMA_CAP_TABLE: typeStrings({
    long: 'Pro-forma Cap Table',
    short: 'Cap Table',
  }),

  // Insurance
  CERTIFICATE_OF_INSURANCE: typeStrings({
    long: 'Certificate of Insurance',
    short: 'COI',
  }),
  INSURANCE_AGREEMENT: typeStrings({
    long: 'Insurance Agreement',
    short: 'Insurance',
  }),
  OTHER_INSURANCE_DOCUMENT: typeStrings({
    long: 'Other Insurance Document',
    short: 'Other Insurance',
  }),

  // Intellectual property
  PATENT: typeStrings({ long: 'Patent Filing', short: 'Patent' }),
  TRADEMARK: typeStrings({ long: 'Trademark Filing', short: 'Trademark' }),
  COPYRIGHT: typeStrings({ long: 'Copyright Filing', short: 'Copyright' }),

  // Debt
  DEBT_AGREEMENT: typeStrings({ long: 'Debt Agreement', short: 'Debt' }),

  // Benefit Plans
  _401K_PLAN: typeStrings({ long: '401(K) Plan', short: '401K' }),
  EMPLOYEE_HANDBOOK: typeStrings({
    long: 'Employee Handbook',
    short: 'Handbook',
  }),
  COMMUTER_BENEFIT_AGREEMENT: typeStrings({
    long: 'Commuter Benefit Agreement',
    short: 'Commuter',
  }),
  HEALTH_INSURANCE_AGREEMENT: typeStrings({
    long: 'Health Insurance Agreement',
    short: 'Health',
  }),
  DENTAL_PLAN_AGREEMENT: typeStrings({
    long: 'Dental Plan Agreement',
    short: 'Dental',
  }),
  VISION_PLAN_AGREEMENT: typeStrings({
    long: 'Vision Plan Agreement',
    short: 'Vision',
  }),

  // Real Estate
  REAL_ESTATE_LEASE: typeStrings({
    long: 'Real Estate Lease',
    short: 'RE Lease',
  }),
  PURCHASED_REAL_ESTATE: typeStrings({
    long: 'Real Estate Purchase Agreement',
    short: 'RE Purchase',
  }),

  // Financials
  AUDITOR_LETTER: typeStrings({ long: 'Auditor Letter', short: 'Auditor' }),
  INCOME_STATEMENT: typeStrings({
    long: 'Income Statement',
    short: 'Income',
  }),
  BALANCE_SHEET: typeStrings({ long: 'Balance Sheet', short: 'Balance' }),
  OTHER_FINANCIAL_STATEMENTS: typeStrings({
    long: 'Other Financial Statements',
    short: 'Other Financials',
  }),
  TAX_FILING: typeStrings({
    long: 'Tax Filing',
    short: 'Tax',
  }),
  DEBT: typeStrings({
    long: 'Debt Document',
    short: 'Debt',
  }),

  // 3rd party agreements
  CUSTOMER_AGREEMENT: typeStrings({
    long: 'Customer Agreement',
    short: 'Customer',
  }),
  JOINT_VENTURES_AGREEMENT: typeStrings({
    long: 'Joint Venture Agreement',
    short: 'Joint Venture',
  }),
  NON_DISCLOSURE_AGREEMENT: typeStrings({
    long: 'Non-Disclosure Agreement',
    short: 'Non-Disclosure',
  }),
  LETTER_OF_INTENT: typeStrings({ long: 'Letter of Intent', short: 'Intent' }),
  MEMORANDUM_OF_UNDERSTANDING: typeStrings({
    long: 'Memorandum of Understanding',
    short: 'Memorandum',
  }),
  // Other documents
  // PARTNERSHIP_AGREEMENT: typeStrings({ long: 'Partnership Agreement' }),
  // VENDOR_AGREEMENT: typeStrings({long: 'Vendor Agreement',short: 'Vendor',}),
  // EQUIPMENT_LEASE: typeStrings({ long: 'Equipment Lease' }),
  // LICENSING_AGREEMENT: typeStrings({ long: 'Licensing Agreement' }),

  // PRIVACY_POLICY: typeStrings({ long: 'Privacy Policy' }),
  // TERMS_OF_SERVICE: typeStrings({ long: 'Terms of Service' }),
  // MASTER_SERVICES_AGREEMENT: typeStrings({ long: 'Master Services Agreement' }),
  // STATEMENT_OF_WORK: typeStrings({ long: 'Statement of Work' }),
  // MATERIALS_SENT_TO_BOARD: typeStrings({ long: 'Materials sent to board' }),
  // NON_COMPETE_RESTRICTION: typeStrings({ long: 'Non-compete restriction' }),
  // COMPANY_TEMPLATE_LEGAL_AGREEMENT: typeStrings({
  //   long: 'Company Template Legal Agreement',
  // }),
  MISCELLANEOUS: typeStrings({ long: 'Miscellaneous', short: 'Misc' }),

  UNCATEGORIZED: typeStrings({
    long: 'Uncategorized',
    short: 'Uncategorized',
  }),
  PROCESSING: typeStrings({ long: 'Aerial Currently Processing ...', short: 'Processing' }),
  NON_LEGAL: typeStrings({
    long: 'Non Legal Document',
    short: 'Non Legal',
  }),
} as const

type DocType = keyof typeof docTypeMap

export const docTypeStr = (type: DocType, short?: boolean): string =>
  short ? docTypeMap[type].short : docTypeMap[type].long
