import type { IndicatorProps } from '@mantine/core'
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Indicator,
  Popover,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useInterval, useTimeout } from '@mantine/hooks'
import { IconSparkles } from '@tabler/icons-react'
import pluralize from 'pluralize'
import * as React from 'react'
import { zIndex } from '~/client/components/z-index'
import { hooks } from '~/client/lib/hooks'
import type { AutocheckValue } from '~/common/autocheck'
import type { ZAugmentedAutocheckIssue } from '~/common/schema/autocheck'

interface BlinkProps {
  blinkDurationMs: number
  blinkIntervalMs: number
  disabled?: boolean
}

/**
 * Returns true for the blinkDurationMs, then false for the next blinkDurationMs.
 * Stops and resets when disabled. This is a workaround because Mantine doesn't
 * have an easy way to add a delay between pulsing animations, and it's too
 * distracting without one.
 * @param blinkIntervalMs
 * @param blinkDurationMs
 * @param disabled
 */
const useBlinkAnimation = ({ blinkIntervalMs, blinkDurationMs, disabled }: BlinkProps) => {
  const startsOn = !disabled
  const [blinking, setBlinking] = React.useState(startsOn)
  const blinkOff = useTimeout(() => setBlinking(false), blinkDurationMs, { autoInvoke: startsOn })
  const interval = useInterval(() => {
    setBlinking(true)
    blinkOff.start()
  }, blinkDurationMs + blinkIntervalMs)

  React.useEffect(() => {
    if (disabled) interval.stop()
    else {
      interval.start()
      return interval.stop
    }
  }, [disabled, interval])
  return blinking
}

interface BlinkingIndicatorProps extends BlinkProps, IndicatorProps {}

const BlinkingIndicator: React.FC<BlinkingIndicatorProps> = ({
  blinkDurationMs,
  blinkIntervalMs,
  disabled,
  children,
  ...props
}) => {
  const blinking = useBlinkAnimation({ blinkDurationMs, blinkIntervalMs, disabled })
  return (
    <Indicator
      position='top-start'
      size={6}
      disabled={disabled}
      styles={{
        root: { display: 'flex' },
      }}
      processing={blinking}
      {...props}
    >
      {children}
    </Indicator>
  )
}

type AutocheckDismissProps = Pick<ZAugmentedAutocheckIssue, 'type' | 'metadataField' | 'docCryptId'>

const DismissAutocheckButton: React.FC<AutocheckDismissProps> = (props) => {
  const { mutate, isLoading } = hooks.trpc().autocheck.setDismissed.useMutationWithCorp()
  return (
    <Button
      color='urgent'
      onClick={() => mutate({ ...props, dismissed: true })}
      loading={isLoading}
    >
      Dismiss Autocheck issue
    </Button>
  )
}

/**
 * Handles autofill.  Given an array of `autofills` as strings,
 * returns the index of the one selected by user (`onAutofill`).
 * This allows the component to be reusable and not depend on the
 * type of the underlying suggestions
 */
export interface AutofillCompProps {
  testid?: string
  autofills?: React.ReactNode[]
  onAutofill: (index: number) => void
  valueIsFilled: boolean
  targetInputDisabled?: boolean
  autocheckDismissProps?: AutocheckDismissProps
  autocheckResult?: AutocheckValue
}

export const AutofillComp: React.FC<AutofillCompProps> = ({
  testid,
  autofills,
  onAutofill,
  valueIsFilled,
  targetInputDisabled,
  autocheckDismissProps,
  autocheckResult = 'ok',
}) => {
  const [opened, setOpened] = React.useState(false)
  const noData = !autofills || autofills.length === 0
  const onlyOneSuggestion = autofills?.length === 1
  const disabled = noData || valueIsFilled || targetInputDisabled

  const tooltipLabel = React.useMemo(() => {
    if (noData) return 'No autofill suggestions'
    if (valueIsFilled) return 'Value already filled'
    if (onlyOneSuggestion && autocheckResult === 'ok') return `Autofill with ${autofills[0]}`

    if (autocheckResult === 'warning')
      return (
        <>
          Autocheck could not find this value in the document. <br />
          Here {pluralize('is', autofills.length)} {autofills.length}{' '}
          {pluralize('suggestion', autofills.length)}
        </>
      )

    return `Autofill (${autofills.length} suggestions)`
  }, [autofills, noData, onlyOneSuggestion, valueIsFilled, autocheckResult])

  const iconColor = (() => {
    if (disabled) return 'inactive'
    if (autocheckResult === 'warning') return 'urgent'
    return 'primary'
  })()

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position='bottom-end'
      disabled={disabled}
      shadow='md'
      withinPortal
      zIndex={zIndex.popover}
    >
      <Popover.Target>
        <Tooltip
          label={tooltipLabel}
          disabled={disabled}
          position='top-end'
          withinPortal
          zIndex={zIndex.popover}
        >
          <ActionIcon
            data-testid={testid}
            color={iconColor}
            onClick={() => {
              if (disabled) return
              if (onlyOneSuggestion && autocheckResult === 'ok') onAutofill(0)
              else setOpened((o) => !o)
            }}
            style={{ overflow: 'visible' }}
          >
            <BlinkingIndicator
              blinkDurationMs={1_000}
              blinkIntervalMs={4_000}
              disabled={disabled}
              color={iconColor}
            >
              <IconSparkles />
            </BlinkingIndicator>
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea>
          <Stack style={{ maxHeight: 200 }}>
            {autofills?.map((item, key) => (
              <Group key={key} mr={2} style={{ justifyContent: 'space-between' }}>
                <Text truncate maw={400}>
                  {item}
                </Text>
                <Button onClick={() => onAutofill(key)}>Autofill</Button>
              </Group>
            ))}
            {autocheckResult === 'warning' && autocheckDismissProps && (
              <>
                <Divider />
                <DismissAutocheckButton {...autocheckDismissProps} />
              </>
            )}
          </Stack>
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  )
}
