import { AugmentedMetadataDate } from '~/common/schema'

interface DisplayDateProp {
  startDate: Date | undefined
}

export const DisplayDate: React.FC<DisplayDateProp> = ({ startDate }) => {
  if (!startDate) {
    return null
  }

  return (
    <>
      Date: <b>{AugmentedMetadataDate.display(startDate)}</b>
    </>
  )
}
