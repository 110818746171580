import type { TextProps } from '@mantine/core'
import {
  Accordion,
  Box,
  Button,
  Group,
  List,
  Loader,
  Progress,
  Text,
  ThemeIcon,
} from '@mantine/core'
import { IconArrowRight, IconCircleCheck, IconX } from '@tabler/icons-react'
import React from 'react'
import { analytics } from '~/client/components/monitoring'
import { useMultiDocStore } from '~/client/components/multi-doc-drop/state'
import { NextLinkOpt } from '~/client/components/util/link'
import { TextWithTooltip } from '~/client/components/util/text-with-tooltip'
import type { DuplicateFile, RejectedFile, UploadingFile } from '~/client/lib/doc-upload'
import { MAX_FILE_SIZE_MB, errorStatus } from '~/client/lib/doc-upload'
import { useCorpCryptId, useCreateDocAndUploadMulti } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { mkCorpRoute } from '~/common/util'

const DimmedText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text size='md' c='dimmed' {...props}>
    {children}
  </Text>
)

interface ResultFrameProps {
  icon: React.ReactNode
  filePath: string
  fileStatus: React.ReactNode
  detail: React.ReactNode
  detailAction?: React.ReactNode
}

const ResultFrame: React.FC<ResultFrameProps> = ({
  icon,
  filePath,
  fileStatus,
  detail,
  detailAction,
}) => {
  return (
    <Accordion.Item value={filePath}>
      <Accordion.Control icon={icon}>
        <Group w='100%' justify='space-between'>
          <TextWithTooltip
            c='dimmed'
            truncate='start'
            inline
            style={{ flexGrow: 1 }}
            // Fix font descenders being cut off
            py='6px'
            my='-6px'
            ta='left'
            maw={theme.other.widths.sm}
          >
            {filePath}
          </TextWithTooltip>
          <Box w='200px' pr='sm'>
            {fileStatus}
          </Box>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Group w='100%' wrap='nowrap'>
          <Box w='20px' style={{ flexShrink: 0, flexGrow: 0 }} /> {/* left icon */}
          <Box style={{ flexShrink: 1, flexGrow: 1 }}>{detail}</Box>
          <Box w='200px' style={{ flexShrink: 0, flexGrow: 0 }}>
            {detailAction}
          </Box>
          {/* status text */}
          <Box w='20px' style={{ flexShrink: 0, flexGrow: 0 }} /> {/* right caret */}
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

const aerialBlurb = 'Aerial only stores signed legal documents as immutable PDF files.'
const invalidFileBlurb = (type: string) =>
  `The ${type} file appears to be invalid.  Please verify that the file is not corrupted and upload again.`

const errorDetailText = (file: RejectedFile): string[] => {
  switch (file.reason) {
    case 'file-too-large':
      return [
        `The maximum file size is ${MAX_FILE_SIZE_MB}MB.  If this is actually multiple documents, please split it into multiple files and upload separately.`,
      ]
    case 'file-invalid-type': {
      switch (file.path.split('.').at(-1)) {
        case 'doc':
        case 'docx':
          return [
            'Is this word document an unsigned draft?  If you are certain it is the final copy, please convert it to PDF before uploading.',
            aerialBlurb,
          ]
        case 'xls':
        case 'xlsx':
          return ['Please convert excel files to PDF before uploading.', aerialBlurb]
        case 'ppt':
        case 'pptx':
          return ['Please convert powerpoint files to PDF before uploading.', aerialBlurb]
        case 'pdf':
          return [invalidFileBlurb('PDF')]
        case 'zip':
        case 'rar':
        case 'gzip':
        case 'gz':
        case '7z':
          return [invalidFileBlurb('ZIP')]
        default:
          return ['Please convert all documents to PDF before uploading.', aerialBlurb]
      }
    }
    case 'unknown':
      return ['File Upload Failed']
  }
}

const ErrorResultItem: React.FC<{ file: RejectedFile }> = ({ file }) => {
  const icon = (
    <ThemeIcon color='danger' display='contents'>
      <IconX />
    </ThemeIcon>
  )

  return (
    <ResultFrame
      icon={icon}
      filePath={file.path}
      fileStatus={<Text>{errorStatus(file)}</Text>}
      detail={
        <List>
          {errorDetailText(file).map((text, key) => (
            <List.Item
              key={key}
              icon={
                <ThemeIcon size='sm' mt='2px' color='gray'>
                  <IconArrowRight />
                </ThemeIcon>
              }
            >
              <DimmedText>{text}</DimmedText>
            </List.Item>
          ))}
        </List>
      }
    />
  )
}

const UploadDuplicate: React.FC<{ file: DuplicateFile }> = ({ file: fileStatus }) => {
  const setUploadingToDuplicateDoc = useMultiDocStore((state) => state.setUploadingToDuplicateDoc)
  const { createDocAndUploadBatch } = useCreateDocAndUploadMulti()
  const { corpCryptId } = useCorpCryptId()

  const { path, file } = fileStatus

  return (
    <Button
      onClick={async () => {
        setUploadingToDuplicateDoc(path)
        // Using the batch version because we need to update the multi doc store
        await createDocAndUploadBatch([
          {
            path,
            docInfo: { type: 'PROCESSING', corpCryptId },
            file,
          },
        ])
        analytics.trackEventSuccess('DUPLICATE_DOC_UPLOAD')
      }}
      size='xs'
      color='gray'
    >
      Upload Anyway
    </Button>
  )
}

const DuplicateResultItem: React.FC<{ file: DuplicateFile }> = ({ file }) => {
  const icon = (
    <ThemeIcon color='danger' display='contents'>
      <IconX size={24} />
    </ThemeIcon>
  )

  const { corpCryptId } = useCorpCryptId()

  return (
    <ResultFrame
      icon={icon}
      filePath={file.path}
      fileStatus={<Text>Duplicate file</Text>}
      detail={
        file.duplicateDocCryptId ? (
          <DimmedText>
            This document is a duplicate of an{' '}
            <NextLinkOpt
              href={mkCorpRoute(corpCryptId, 'doc', file.duplicateDocCryptId.idStr)}
              target='_blank'
              inline
            >
              existing file
            </NextLinkOpt>{' '}
            that was uploaded.
          </DimmedText>
        ) : (
          <DimmedText>
            This document is a duplicate of another document that was just uploaded in this batch.
          </DimmedText>
        )
      }
      detailAction={<UploadDuplicate file={file} />}
    />
  )
}

const UploadProgress: React.FC<{ file: UploadingFile }> = ({ file }) => (
  <Progress
    value={file.uploadStatePercent}
    size='md'
    color={!file.completed ? 'urgent' : 'green'}
    animated={!file.completed}
  />
)

const UploadingResultItem: React.FC<{ file: UploadingFile }> = ({ file }) => {
  const icon = file.completed ? (
    <ThemeIcon color='go' display='contents'>
      <IconCircleCheck size={24} />
    </ThemeIcon>
  ) : (
    <Loader size={24} />
  )

  return (
    <ResultFrame
      icon={icon}
      filePath={file.path}
      fileStatus={<UploadProgress file={file} />}
      detail={
        <DimmedText>
          {file.completed ? <>File uploaded.</> : <>File uploading &hellip;</>}
        </DimmedText>
      }
    />
  )
}

export const UploadedFileListItem: React.FC<{ path: string }> = ({ path }) => {
  const getFileStatus = useMultiDocStore((state) => state.getFileStatus)
  const file = getFileStatus(path)

  switch (file.status) {
    case 'rejected':
      return <ErrorResultItem file={file} />
    case 'duplicate':
      return <DuplicateResultItem file={file} />
    case 'uploading':
      return <UploadingResultItem file={file} />
  }
}
