import { Button, ThemeIcon } from '@mantine/core'
import { IconLink, IconPlus } from '@tabler/icons-react'
import React from 'react'
import type { DocDetailProp } from '~/client/components/doc-detail/state'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { DocComponentsLayout, MissingAndDocsView } from '~/client/components/doc-view'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import type { ZDocType } from '~/common/schema'
import { typeAugmentedRelationMap } from '~/common/schema/relation'
import type { UseSetLinkMutationResult } from './util'

interface ActionsMenuProps {
  openDocDetail: (state?: DocDetailProp | undefined) => void
  openSearchModal: (type?: ZDocType) => void
}
export const ActionsButtons: React.FC<ActionsMenuProps> = ({ openDocDetail, openSearchModal }) => {
  const { data: auth } = useCurrentCorpAuth()
  const isInvestor = auth?.level === 'investor'

  return (
    <>
      <Button
        disabled={isInvestor}
        data-testid='link-documents'
        onClick={() => openSearchModal()}
        leftSection={
          isInvestor ? (
            <IconLink />
          ) : (
            <ThemeIcon>
              <IconLink />
            </ThemeIcon>
          )
        }
      >
        Link Documents
      </Button>
      <Button
        disabled={isInvestor}
        data-testid='add-document'
        onClick={() => openDocDetail()}
        leftSection={
          isInvestor ? (
            <IconPlus />
          ) : (
            <ThemeIcon>
              <IconPlus />
            </ThemeIcon>
          )
        }
      >
        Add Document
      </Button>
    </>
  )
}

interface DocsAndButtonsProps {
  data: EnhancedRelation
  redFlagInfo: RedFlagInfo | undefined
  setLink: UseSetLinkMutationResult
  openSearchModal: (type?: ZDocType) => void
}
export const DocComponents: React.FC<DocsAndButtonsProps> = ({
  data,
  setLink,
  redFlagInfo,
  openSearchModal,
}) => {
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)
  const allowedTypes = typeAugmentedRelationMap[data.type].supportedTypes

  const linkOptions = {
    type: 'relation',
    cryptId: data.cryptId,
    relationDisplay: data.display,
  } as const

  const docAutoFill = {
    types: [],
    titles: [],
    emails: [],
    ...data.autofill,
    startDates: data.autofill.startDates.map((date) => date.value).filter(Boolean),
  }

  return (
    <DocComponentsLayout
      headerElements={
        <ActionsButtons
          openDocDetail={() =>
            openDocDetail({
              allowedTypes,
              linkOptions,
              autofill: docAutoFill,
            })
          }
          openSearchModal={() => openSearchModal()}
        />
      }
    >
      <MissingAndDocsView
        allowedTypes={allowedTypes}
        docs={data.docs}
        link={(type) => openSearchModal(type)}
        missingDocs={redFlagInfo?.missingDocs}
        upload={(type) =>
          openDocDetail({
            preselectedType: type,
            allowedTypes,
            linkOptions,
            autofill: docAutoFill,
          })
        }
        sourceCryptIds={data.sourceCryptIds}
        setLink={setLink}
        autofill={docAutoFill}
        linkOptions={linkOptions}
      />
    </DocComponentsLayout>
  )
}
