import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateOptionPlan } from '~/common/schema/relation'
import { ZAugmentedOptionPlan } from '~/common/schema/relation'

export const OptionPlanMetadataTable: React.FC<
  DetailTableProps<ZUpdateOptionPlan, ZAugmentedOptionPlan>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedOptionPlan.shape.startDate.mkDisplayProps(
          data,
          update,
          'startDate'
        )}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedOptionPlan.shape.endDate.mkDisplayProps(data, update, 'endDate')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedOptionPlan.shape.poolSize.mkDisplayProps(data, update, 'poolSize')}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
