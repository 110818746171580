import type {
  WithIndexNumber,
  ZAugmentedDoc,
  ZEmailLowerCase,
  ZMinimalDoc,
  ZParty,
} from '~/common/schema'

export interface DocAutofill {
  titles: string[]
  parties: ZParty[]
  startDates: Date[]
  emails: ZEmailLowerCase[]
  types: ZAugmentedDoc['type'][]
}

export type EnhancedDoc<TDoc extends ZMinimalDoc = ZAugmentedDoc> = WithIndexNumber<TDoc> & {
  indexList?: number[]
  key: string
}

export const enhanceDoc = <TDoc extends ZMinimalDoc = ZAugmentedDoc>(
  doc: WithIndexNumber<TDoc>,
  parentIndexList?: number[]
): EnhancedDoc<TDoc> => {
  const indexList = parentIndexList?.concat(doc.indexNumber)

  return {
    ...doc,
    key: doc.cryptId.idStr,
    indexList,
  }
}
