import jwtDecode, { type JwtPayload } from 'jwt-decode'
import { z } from 'zod'
import { ZPermissionsLevel } from '~/common/schema'

export const ZOtpJwtPayload = z.object({
  otpVerified: z.boolean().optional(),
})
export interface ZOtpJwtPayload extends z.infer<typeof ZOtpJwtPayload> {}

export const ZDocusignJwtPayload = z.object({
  docusignUserId: z.string(),
})
export interface ZDocusignJwtPayload extends z.infer<typeof ZDocusignJwtPayload> {}

export const ZCorpAuthJwtPayload = z.object({
  corpCryptIdStr: z.string(),
  level: ZPermissionsLevel,
  superAdmin: z.boolean().optional(),
})

export interface ZCorpAuthJwtPayload extends z.infer<typeof ZCorpAuthJwtPayload> {}

export const ZJwtPayload = z.union([ZOtpJwtPayload, ZDocusignJwtPayload, ZCorpAuthJwtPayload])
export type ZJwtPayload = z.infer<typeof ZJwtPayload>

const getPayloadSafe = (jwt: string) => {
  try {
    return jwtDecode<JwtPayload>(jwt)
  } catch {
    return undefined
  }
}

export const decodeJwtPayloadSafe = <
  TZJwtPayload extends
    | typeof ZOtpJwtPayload
    | typeof ZDocusignJwtPayload
    | typeof ZCorpAuthJwtPayload,
>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _ZJwtPayload: TZJwtPayload,
  jwt: string | undefined
): z.infer<TZJwtPayload> | undefined => {
  if (!jwt) {
    return undefined
  }
  const payload = getPayloadSafe(jwt)
  if (!payload || !payload.iat) {
    return undefined
  }
  const parsed = _ZJwtPayload.safeParse(payload)
  return parsed.success ? parsed.data : undefined
}
