import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateValuation } from '~/common/schema/relation'
import { ZAugmentedValuation } from '~/common/schema/relation'

export const ValuationMetadataTable: React.FC<
  DetailTableProps<ZUpdateValuation, ZAugmentedValuation>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.startDate.mkDisplayProps(data, update, 'startDate')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.endDate.mkDisplayProps(data, update, 'endDate')}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedValuation.shape.valuation.mkDisplayProps(data, update, 'valuation')}
        size={size}
      />
    </Table.Tbody>
  </Table>
)
