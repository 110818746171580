import { CryptId } from '@cryptid-module'
import { ActionIcon, Group, Select, Tooltip } from '@mantine/core'
import { IconEdit, IconEditOff } from '@tabler/icons-react'
import * as React from 'react'
import { analytics } from '~/client/components/monitoring'
import { CopyableTooltip } from '~/client/components/util'
import { AutofillComp, type AutofillCompProps } from '~/client/components/util/autofill-comp'
import { mkSelectFilter } from '~/client/components/util/select-filter'
import { zIndex } from '~/client/components/z-index'
import { metadataRowHoverClasses } from '~/client/lib/css-util.css'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedDoc } from '~/common/schema'
import { AugmentedMetadataDate, docTypeStr } from '~/common/schema'

interface HoverEditProps extends Omit<AutofillCompProps, 'testid'> {
  onClick?: () => void
  alignment?: 'end' | 'start'
  noWrap?: boolean
  isEmpty?: boolean
}

// Ref required by Mantine: https://mantine.dev/core/popover/#required-ref-prop
export const HoverEdit: React.FC<HoverEditProps> = React.forwardRef<HTMLDivElement, HoverEditProps>(
  (
    { children, onClick, onAutofill, autofills, isEmpty, valueIsFilled, alignment, noWrap },
    ref
  ) => {
    const { data: auth } = useCurrentCorpAuth()
    const isInvestor = auth?.level === 'investor'

    const divRef = React.useRef<HTMLDivElement>(null)

    return (
      // ref required so Mantine can position the Popover.
      <Group
        ref={ref}
        style={{
          columnGap: theme.spacing.xs,
          rowGap: '2px',
          flexWrap: noWrap ? 'nowrap' : undefined,
        }}
      >
        <Group
          style={{
            order: alignment === 'start' ? 1 : 0,
            flexWrap: 'nowrap',
            gap: theme.spacing.xs,
          }}
          className={metadataRowHoverClasses.appear}
        >
          {!!onClick && !isInvestor ? (
            <Tooltip label='Edit'>
              <ActionIcon onClick={onClick} component='span' data-testid='hover-edit'>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          ) : (
            <ActionIcon
              disabled
              component='span'
              style={{
                pointerEvents: 'all',
              }}
            >
              <IconEditOff />
            </ActionIcon>
          )}
          <AutofillComp
            targetInputDisabled={isInvestor}
            autofills={autofills}
            onAutofill={onAutofill}
            valueIsFilled={valueIsFilled}
          />
          <CopyableTooltip
            disabled={isEmpty}
            textStringOrRef={divRef}
            onCopy={() => analytics.trackEventSuccess('COPY_METADATA')}
          />
        </Group>
        {!!children && (
          <Group
            ref={divRef}
            align='center'
            style={{
              gap: theme.spacing.xs,
              whiteSpace: 'pre-wrap' /* Allow newlines for string children */,
              order: alignment === 'start' ? 0 : 1,
              flexWrap: 'nowrap',
            }}
          >
            {children}
          </Group>
        )}
      </Group>
    )
  }
)

const getLabel = (doc: ZAugmentedDoc) => {
  switch (doc.type) {
    case 'PROCESSING':
      return `${docTypeStr(doc.type)} (Processing)`
    case 'UNCATEGORIZED':
      return `${docTypeStr(doc.type)} (Uncategorized)`
    default: {
      const info = AugmentedMetadataDate.display(doc.startDate)
      return `${docTypeStr(doc.type)}${info ? ` (${info})` : ''}`
    }
  }
}

const docRefSelectFilter = mkSelectFilter(
  (value, item) =>
    item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
    item.value.toLowerCase().includes(value.toLowerCase().trim())
)
export const DocRefSelect: React.FC<{
  docs: ZAugmentedDoc[]
  setCryptId: React.Dispatch<CryptId | undefined>
  cryptId: CryptId | undefined
}> = ({ docs, cryptId, setCryptId }) => {
  const selectData = React.useMemo(
    () =>
      docs.map((doc) => ({
        value: doc.cryptId.idStr,
        label: getLabel(doc),
      })),
    [docs]
  )
  return (
    <Select
      placeholder='Source'
      searchable
      clearable
      selectFirstOptionOnChange
      data={selectData}
      filter={docRefSelectFilter}
      styles={{ dropdown: { zIndex: zIndex.modal } }}
      value={cryptId?.idStr}
      onChange={(value) => {
        setCryptId(value ? new CryptId(value) : undefined)
      }}
      data-testid='doc-ref-select'
      // DatePicker popover should set `withinPortal` to false, because
      // clicking an option will close the parent popover (inline edit
      // popover).
      // https://github.com/mantinedev/mantine/issues/2144#issuecomment-1217173512
      comboboxProps={{ withinPortal: false }}
    />
  )
}

export const inlineInputWidth = 250
