import { Group, Stack, ThemeIcon } from '@mantine/core'
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone'
import { IconAlertTriangle, IconFile } from '@tabler/icons-react'
import React from 'react'
import { useDocDetailState, useFormSaveMethods } from '~/client/components/doc-detail/state'
import { analytics } from '~/client/components/monitoring'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import { acceptIfPDFandSizeAllowed, errorStatus } from '~/client/lib/doc-upload'
import { hooks } from '~/client/lib/hooks'
import { computeSHA256 } from '~/client/lib/util'
import { extractDocusignIdFromPdf } from '~/common/extract-docusign-id'
import { atOrThrow } from '~/common/util'
import { DropError, UploadFiles } from './util'

const formatErrorMessage = (message: string): string => {
  if (message.startsWith('File type must be')) return 'File type must be PDF'
  return message
}

export const promptDuplicateUpload = (): Promise<boolean> =>
  promptConfirmAsync({
    title: (
      <Group>
        <ThemeIcon color='danger'>
          <IconAlertTriangle />
        </ThemeIcon>
        Duplicate Document
      </Group>
    ),
    subtitle:
      'This document already exists in the system. Are you sure you want to upload it again?',
    confirmText: 'Upload duplicate doc',
    cancelText: 'Cancel upload',
    buttonsColorVariant: 'discourage',
  })

export const SingleDocDrop: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const form = useDocDetailState((state) => state.form)
  const { save } = useFormSaveMethods()
  const setDropError = useDocDetailState((state) => state.setDropError)
  const fetchIsDuplicate = hooks.trpc().doc.isDuplicate.useFetchWithCorp()
  const sendDocUploadedEmail = hooks
    .trpc()
    .sendDocUploadedEmail.useMutationWithCorp({ meta: { noErrorNotification: true } })

  /**
   * Prompt the user for upload confirmation if a doc with the same SHA256 has been already uploaded.
   *
   * @returns `true` if the user wants to proceed with uploading the doc
   * (including if the doc is not a duplicate), `false` otherwise
   */
  const shouldUploadDuplicateDoc = React.useCallback(
    async (file: File): Promise<boolean> => {
      const sha256 = await computeSHA256(file)
      const docusignEnvelopeId = extractDocusignIdFromPdf(await file.arrayBuffer())
      const { duplicateDocCryptId } = await fetchIsDuplicate({ sha256, docusignEnvelopeId })
      if (duplicateDocCryptId) {
        const shouldUpload = await promptDuplicateUpload()
        if (shouldUpload) analytics.trackEventSuccess('DUPLICATE_DOC_UPLOAD')
        return shouldUpload
      }
      return true
    },
    [fetchIsDuplicate]
  )
  const withLoadingState = async (func: () => Promise<void>) => {
    setIsLoading(true)
    await func()
    setIsLoading(false)
  }
  return (
    <Stack h='100%'>
      <Dropzone
        loading={isLoading}
        activateOnClick
        onDrop={async (files) => {
          await withLoadingState(async () => {
            const fileWithPath = atOrThrow(files, 0)
            const file = await acceptIfPDFandSizeAllowed(fileWithPath)
            if (file.status === 'rejected') {
              setDropError(errorStatus(file))
              return
            }
            if (!(await shouldUploadDuplicateDoc(file.file))) {
              return
            }
            form.setFieldValue('file', fileWithPath)
            const docCryptId = await save({ ...form.values, file: fileWithPath })

            sendDocUploadedEmail.mutate({ docCryptIds: [docCryptId] })
            analytics.trackEventSuccess('SINGLE_FILE_UPLOAD')
          })
        }}
        onReject={(rejectedFiles) => {
          const { message } = rejectedFiles[0]?.errors[0] ?? { message: '' }
          setDropError(formatErrorMessage(message))
        }}
        accept={PDF_MIME_TYPE}
        multiple={false}
        styles={{ inner: { height: '100%', pointerEvents: 'all' } }}
        data-testid='drop-zone'
        style={{
          flexGrow: 1,
        }}
      >
        <UploadFiles
          icon={
            <ThemeIcon size={64}>
              <IconFile size={64} stroke={1} />
            </ThemeIcon>
          }
          title='Drag document here'
          browseText='Browse File'
          inlineIcon
        />
      </Dropzone>
      <DropError />
    </Stack>
  )
}
