import type { ModalProps } from '@mantine/core'
import { Tooltip } from '@mantine/core'
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone'
import React from 'react'
import { UploadFiles } from '~/client/components/doc-detail'
import { DocFrameModal } from '~/client/components/modals'
import { analytics } from '~/client/components/monitoring'
import { OnboardingModal } from '~/client/components/onboarding-modal'
import promptConfirmAsync from '~/client/components/util/prompt-confirm-async'
import type { DuplicateFile, FileUploadingStatus, RejectedFile } from '~/client/lib/doc-upload'
import { ZIP_MIME_TYPES, coarseRejectionReason, extractDroppedFiles } from '~/client/lib/doc-upload'
import {
  hooks,
  useCorpCryptId,
  useCorpDelayCall,
  useCreateDocAndUploadMulti,
  useCurrentCorpAuth,
} from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { computeSHA256 } from '~/client/lib/util'
import { extractDocusignIdFromPdf } from '~/common/extract-docusign-id'
import { onboardingModalSteps, useMultiDocModalStore, useMultiDocStore } from './state'
import { FilesUploaded } from './upload-result'
import { MultiDocUploadIcons, showOnboardingDismissedNotification } from './util'

export const investorsUploadBlurb =
  'With investor permissions, you cannot upload documents. The company can drop documents here and Aerial’s AI will organize them automatically.'

const useComputeDuplicates = () => {
  const fetchIsDuplicate = hooks.trpc().doc.isDuplicate.useFetchWithCorp()

  const computeFileDuplicate = async (
    fileStatus: FileUploadingStatus,
    currentUploadSHA256List: string[]
  ) => {
    if (fileStatus.status !== 'uploading') return fileStatus
    const sha256 = await computeSHA256(fileStatus.file)
    const docusignEnvelopeId = extractDocusignIdFromPdf(await fileStatus.file.arrayBuffer())
    // check if file already exists in current upload
    // This has to be done separately from the next `if` to avoid
    // inconsistencies due to this check not being atomic.
    const isLocalDuplicate = currentUploadSHA256List.includes(sha256)
    if (!isLocalDuplicate) currentUploadSHA256List.push(sha256)

    // check if file already exists in current upload or in db
    // Always check the db, even if we know the doc is duplicated in the batch
    const { duplicateDocCryptId } = await fetchIsDuplicate({ sha256, docusignEnvelopeId })
    const isDbDuplicate = !!duplicateDocCryptId
    if (isLocalDuplicate || isDbDuplicate) {
      const newFileStatus: DuplicateFile = {
        ...fileStatus,
        status: 'duplicate',
        duplicateDocCryptId,
        sha256,
      }
      return newFileStatus
    }
    return fileStatus
  }

  return (fileStatuses: FileUploadingStatus[]) => {
    const currentUploadSHA256List: string[] = []
    return Promise.all(
      fileStatuses.map(async (fileStatus): Promise<FileUploadingStatus> => {
        try {
          return await computeFileDuplicate(fileStatus, currentUploadSHA256List)
        } catch (e) {
          // It is very unlikely that an error happens here,
          // but if it does, it could leave the upload in an
          // infinite loading state, sp we should handle it
          return {
            status: 'rejected',
            path: fileStatus.path,
            reason: 'unknown',
          }
        }
      })
    )
  }
}

export const MultiDocDrop: React.FC<{
  onAfterDocUpload?: () => void
  navigateToDocStatusAfterUpload?: boolean
}> = ({ onAfterDocUpload, navigateToDocStatusAfterUpload = true }) => {
  const { data: auth } = useCurrentCorpAuth()
  const sendDocUploadedMutation = hooks
    .trpc()
    .sendDocUploadedEmail.useMutationWithCorp({ meta: { noErrorNotification: true } })
  const setFileStatuses = useMultiDocStore((state) => state.setFileStatuses)
  const openModal = useMultiDocModalStore((state) => state.openModal)
  const modalState = useMultiDocModalStore((state) => state.state)
  const updateModalState = useMultiDocModalStore((state) => state.updateState)
  const computeDuplicates = useComputeDuplicates()
  const { createDocAndUploadBatch } = useCreateDocAndUploadMulti()
  const { corpCryptId } = useCorpCryptId()
  const { corpDelayCall, loading } = useCorpDelayCall()
  const isInvestor = auth?.level === 'investor'

  return (
    <Tooltip
      multiline
      disabled={!isInvestor}
      w={theme.other.widths.sm}
      label={investorsUploadBlurb}
    >
      <Dropzone
        loading={loading}
        disabled={isInvestor}
        onDropAny={corpDelayCall(async (corp, acceptedFiles, invalidFiles) => {
          const rejectedFiles: RejectedFile[] = invalidFiles.map((file) => {
            return {
              status: 'rejected',
              path: file.file.name,
              reason: coarseRejectionReason(file.errors[0]?.code),
              message: file.errors[0]?.message,
            }
          })
          const extractedFiles = await extractDroppedFiles(acceptedFiles)
          const allFiles: FileUploadingStatus[] = [...extractedFiles, ...rejectedFiles].map(
            (file) => {
              if (file.status === 'accepted')
                return {
                  ...file,
                  status: 'uploading',
                  uploadState: 0,
                  uploadStatePercent: 0,
                }
              return file
            }
          )

          const confirmation = await promptConfirmAsync({
            title: 'Confirm Upload',
            subtitle: (
              <>
                {'Are you sure you want to upload '}
                <b>{`${allFiles.length} ${allFiles.length === 1 ? 'file' : 'files'}`}</b>
                {' to '}
                <b>{corp.name.value}</b>
                {' ?'}
              </>
            ),
            confirmText: 'Upload',
            confirmProps: {
              'data-testid': 'confirm-upload-files',
            },
            cancelProps: {
              'data-testid': 'cancel-upload-files',
            },
            buttonsColorVariant: 'encourage',
          })

          if (!confirmation) return

          setFileStatuses(allFiles)

          if (modalState === 'closed' && navigateToDocStatusAfterUpload) {
            openModal('doc-status')
          }
          if (modalState !== 'closed' && navigateToDocStatusAfterUpload) {
            updateModalState('doc-status')
          }
          onAfterDocUpload?.()

          const allFilesWithDuplicates = await computeDuplicates(allFiles)
          setFileStatuses(allFilesWithDuplicates)

          const inputs = allFilesWithDuplicates.flatMap((fileStatus) => {
            if (fileStatus.status !== 'uploading') return []

            const { file, path } = fileStatus
            return [
              {
                docInfo: { type: 'PROCESSING' as const, corpCryptId },
                file,
                path,
              },
            ]
          })
          const docCryptIds = (await createDocAndUploadBatch(inputs)).map((doc) => doc.cryptId)
          if (docCryptIds.length > 0) sendDocUploadedMutation.mutate({ docCryptIds })

          analytics.trackEventSuccess('MULTI_FILE_UPLOAD', {
            uploaded: inputs.length,
            rejectedDuplicate: allFilesWithDuplicates.filter((file) => file.status === 'duplicate')
              .length,
            rejectedTooLarge: allFilesWithDuplicates.filter(
              (file) => file.status === 'rejected' && file.reason === 'file-too-large'
            ).length,
            rejectedInvalidType: allFilesWithDuplicates.filter(
              (file) => file.status === 'rejected' && file.reason === 'file-invalid-type'
            ).length,
            rejectedUnknown: allFilesWithDuplicates.filter(
              (file) => file.status === 'rejected' && file.reason === 'unknown'
            ).length,
          })
        })}
        // prop is required
        onDrop={() => {}}
        accept={[...PDF_MIME_TYPE, ...ZIP_MIME_TYPES]}
        multiple
        styles={{
          inner: {
            height: '100%',
          },
          root: {
            height: '100%',
            pointerEvents: 'all',
            width: '100%',
          },
        }}
        data-testid='drop-zone'
      >
        <UploadFiles
          icon={<MultiDocUploadIcons />}
          title='Drag documents, folders, or Zip files here.'
          browseText='Browse Files'
        />
      </Dropzone>
    </Tooltip>
  )
}

const MultiDocDropModalBase: React.FC<{
  topLeftSection?: React.ReactNode
  opened: boolean
  withCloseButton?: boolean
  styles?: ModalProps['styles']
}> = ({ topLeftSection, opened, children, withCloseButton = true, styles }) => {
  const modalState = useMultiDocModalStore((state) => state.state)
  const onClose = () => {
    const { closeModal, openModal } = useMultiDocModalStore.getState()
    closeModal()
    if (onboardingModalSteps.includes(modalState) && modalState !== 'onboarding-done') {
      showOnboardingDismissedNotification(() => openModal('onboarding'))
    }
  }

  return (
    <DocFrameModal
      title={topLeftSection}
      opened={opened}
      onClose={onClose}
      withCloseButton={withCloseButton}
      styles={styles}
    >
      {children}
    </DocFrameModal>
  )
}

export const MultiDocDropModal: React.FC = () => {
  const modalState = useMultiDocModalStore((state) => state.state)

  switch (modalState) {
    case 'doc-drop':
      return (
        <MultiDocDropModalBase topLeftSection='Upload documents, folders, or Zip files.' opened>
          <MultiDocDrop />
        </MultiDocDropModalBase>
      )

    case 'doc-status':
      return (
        <MultiDocDropModalBase topLeftSection='Upload documents, folders, or Zip files.' opened>
          <FilesUploaded />
        </MultiDocDropModalBase>
      )
    // We need to always have the modal in the DOM otherwise the open/close transitions won't work
    case 'closed':
      return <MultiDocDropModalBase opened={false} />

    default:
      return (
        <MultiDocDropModalBase
          opened
          withCloseButton={false}
          styles={{
            body: { height: '85vh', display: 'flex', flexDirection: 'column', padding: 0 },
          }}
        >
          <OnboardingModal />
        </MultiDocDropModalBase>
      )
  }
}
